import React, { memo, useCallback } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Select from "components/select";
import { Field } from "react-final-form";
import { useStyles } from "components/events/styles";
import TextField from "components/textField";
import { maxCharacters } from "shared/form/validators";
import { actions } from "store/eventModal/slice";
import { useDispatch } from "react-redux";
import Divider from "components/typography/heading/divider";

interface IAlertStatusSelect {
  alertStatuses: any[];
  disabled: boolean;
  setStatus: (status: string) => void;
  status: string | null;
  statusDefault: string | null;
  isCritical: boolean | null;
  isCriticalDefault: boolean | null;
  presumedCauses: string | null;
  recommendedAction: string | null;
  anomalyTrend: string | null;
  diagDescription: string | null;
}

export const AlertStatusSelect = memo(
  ({
    alertStatuses,
    setStatus,
    disabled,
    statusDefault,
    isCritical,
    isCriticalDefault,
    diagDescription,
    anomalyTrend,
    presumedCauses,
    recommendedAction,
  }: IAlertStatusSelect) => {
    const dispatch: any = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();

    const [alertStatusActive, setAlertStatusActive] = React.useState<
      string | null
    >(statusDefault);

    const CUSTOMER_INFORMED_STATUS = "customer_informed";

    const options = alertStatuses
      ? alertStatuses.map((item: any) => ({
          ...item,
          text: t(`alert_status.${item.text}`),
        }))
      : [];

    const isCriticalOptions = [
      { value: -1, text: t("is_critical.null") },
      { value: 1, text: t("is_critical.true") },
      { value: 0, text: t("is_critical.false") },
    ];

    React.useEffect(() => {
      if (alertStatusActive) {
        setStatus(alertStatusActive);
      }
    }, [alertStatusActive, setStatus]);

    const setIsCritical = useCallback(
      (isCritical: any) => {
        dispatch(actions.setIsCritical({ isCritical }));
      },
      [dispatch]
    );
    React.useEffect(() => {
      setAlertStatusActive(statusDefault);
    }, [statusDefault]);

    React.useEffect(() => {
      setIsCritical(isCriticalDefault);
    }, [isCriticalDefault, setIsCritical]);

    const canDisplayTextFields = alertStatusActive === CUSTOMER_INFORMED_STATUS;
    const textFieldAreWritable = statusDefault !== CUSTOMER_INFORMED_STATUS;

    const textFieldCommonProps = {
      multiline: true,
      rowsMax: 5,
      disabled: !textFieldAreWritable,
      validate: maxCharacters(50000),
      component: TextField,
      className: classes.input,
    };

    return (
      <>
        <Divider line>{t("eventModal.titles.alertStatus")}</Divider>
        <Box className={`${classes.select} ${classes.selectSpacing}`} ml={-1.5}>
          <Field
            id="alertStatusSelect"
            name="status"
            component={Select}
            label={t("eventModal.titles.alertStatus")}
            options={options}
            disabled={disabled}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setAlertStatusActive(e.target.value)
            }
            required
            value={statusDefault}
          />
        </Box>
        {canDisplayTextFields && (
          <>
            <Box
              className={`${classes.select} ${classes.selectSpacing}`}
              ml={-1.5}
            >
              <Field
                id="isCritical"
                name="isCritical"
                disabled={!textFieldAreWritable}
                component={Select}
                label={t("eventModal.titles.isCritical")}
                options={isCriticalOptions}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  setIsCritical(
                    parseInt(e.target.value) < 0
                      ? null
                      : !!parseInt(e.target.value)
                  )
                }
                value={
                  isCriticalDefault === null ? -1 : isCriticalDefault ? 1 : 0
                }
              />
            </Box>
            {isCritical !== null && (
              <Box py={1.5}>
                <Box mb={3}>
                  <Field
                    name="diagDescription"
                    id="testDiagDescriptionField"
                    label={t("eventModal.titles.diagDescription")}
                    defaultValue={diagDescription || ""}
                    {...textFieldCommonProps}
                  />
                </Box>
                <Box mb={3}>
                  <Field
                    name="presumedCauses"
                    id="testPresumedCausesField"
                    label={t("eventModal.titles.presumedCauses")}
                    defaultValue={presumedCauses || ""}
                    {...textFieldCommonProps}
                  />
                </Box>
                <Box mb={3}>
                  <Field
                    name="recommendedAction"
                    id="testRecommendedActionField"
                    label={t("eventModal.titles.recommendedAction")}
                    defaultValue={recommendedAction || ""}
                    {...textFieldCommonProps}
                  />
                </Box>
                <Box>
                  <Field
                    name="anomalyTrend"
                    id="testAnomalyTrendField"
                    label={t("eventModal.titles.anomalyTrend")}
                    defaultValue={anomalyTrend || ""}
                    {...textFieldCommonProps}
                  />
                </Box>
              </Box>
            )}
          </>
        )}
      </>
    );
  }
);
