import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

type TParams = {
  id: number;
  machine: number;
  start?: {
    type: string;
    created_at: string;
    note: string;
    meta?: {
      purpose: string;
    };
    placements?: number[];
  };
  end?: {
    type: string;
    created_at: string;
    note: string;
  };
};

export default async function patchPairEvent(event: TParams) {
  const { id, ...rest } = event;
  const url = endpoints.events.detail(id);
  try {
    const response = await axiosNeuron.patch(`${url}pair/`, rest);
    return response.data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
