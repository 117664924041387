import { EActions, TokenActionTypes } from "store/forgottenPassword/types";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import forgotPassword from "api/handlers/password/forgotPassword";
import { prepareActions } from "store/helpers";
import { Params } from "api/handlers/password/forgotPassword";
import i18n from "localization";
import notifierActions from "store/notifier/actions";

export const path = "token";

const actionsData = [
  [EActions.restorePasswordRequest],
  [EActions.restorePasswordSuccess],
  [EActions.restorePasswordFail, "error"],
  [EActions.restorePasswordReset],
];

export const actions = prepareActions<TokenActionTypes, EActions>(
  actionsData,
  path
);

export default actions;

export const restorePassword =
  ({ email }: Params) =>
  async (dispatch: ThunkDispatch<{}, {}, Action<any>>) => {
    try {
      dispatch(actions.restorePasswordRequest());
      const response = await forgotPassword({ email });
      if (response.status === "send") {
        dispatch(actions.restorePasswordSuccess());
      }
    } catch (error: any) {
      let message = i18n.t("resetPassword.message.errorDefault");
      if (error.message === "User_does_not_exist") {
        message = i18n.t("resetPassword.message.userNotExist");
      }
      dispatch(notifierActions.enqueueSnackbar(message));
      dispatch(actions.restorePasswordFail(error));
    }
  };
