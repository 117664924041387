import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

type TParams = {
  machine: number;
  start?:
    | {
        type: string;
        created_at: string;
        note: string;
        meta?: {
          purpose: string;
        };
        placements?: number[];
      }
    | undefined;
  end?: {
    type: string;
    created_at: string;
    note: string;
  };
};

export default async function addEvent(event: TParams) {
  const url = endpoints.events.pair;
  try {
    const response = await axiosNeuron.post(url, event);
    return response.data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
