import React, { memo, useCallback } from "react";
import Divider from "components/typography/heading/divider";
import { Field, Form } from "react-final-form";
import Select from "components/select";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { createSelector } from "reselect";
import { AppState } from "store";
import { useSelector, useDispatch } from "react-redux";
import isEqual from "react-fast-compare";
import Spinner from "components/spinner";
import actions from "store/deviceDetail/channelsList/actions";
import { makeStyles } from "@mui/styles";
import { TRecordingPlans } from "types/device";
import i18n from "localization";

const useStyles = makeStyles((theme: any) => ({
  root: {
    maxWidth: 326,
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(2),
    },
  },
}));

const selector = createSelector(
  (state: AppState) => state.deviceDetail.detail,
  (state: AppState) => state.deviceDetail.channelsList,
  ({ device }, { recordingPlans, recordingPlan }) => {
    return {
      data: recordingPlans?.map((item: TRecordingPlans) => ({
        text: i18n.t(`recordingPlan.${item.codename}`),
        value: item.codename,
      })),
      statePlan: recordingPlan,
      picked:
        recordingPlans?.find(
          ({ codename }: TRecordingPlans) => codename === device?.recordingPlan
        ) ?? "",
    };
  }
);

type TProps = {
  disabled: boolean;
};

const RecordingPlans = memo(({ disabled }: TProps) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const classes = useStyles();
  const { data, picked, statePlan } = useSelector(selector, isEqual);

  const handleChange = useCallback(
    (e: any) => {
      dispatch(actions.setRecordingPlan(e.target.value));
    },
    [dispatch]
  );

  if (!data || !data.length) {
    return <Spinner />;
  }
  return (
    <>
      <Divider line>{t("devices.detail.recordingPlans")}</Divider>
      <Box className={classes.root}>
        <Form
          onSubmit={() => {}}
          initialValues={{ recordingPlan: statePlan || picked?.codename }}
          render={() => (
            <form>
              <Field
                name="recordingPlan"
                render={({ input, meta }) => (
                  <Select
                    id="recordingPlan"
                    label={t("devices.detail.recordingPlans")}
                    disabled={disabled}
                    displayTags
                    input={input}
                    onChange={handleChange}
                    meta={meta}
                    name="recordingPlan"
                    options={data}
                  />
                )}
              />
            </form>
          )}
        />
      </Box>
    </>
  );
});

export default RecordingPlans;
