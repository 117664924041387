import { EActions, IAudioUploaderState, IActionTypes } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

export const initialState: IAudioUploaderState = {
  wizardOpen: false,
  introPage: true,
  recordingStart: false,
  recordingStartDatetime: null,
  uploadingStartDatetime: null,
  uploadingStart: false,
  uploading: false,
  audioReady: false,
  audioData: "",
  placementsLoading: false,
  placements: undefined,
  payloadPlacements: [],
  metaData: {},
  saving: false,
  stereo: false,
  deviceId: undefined,
};

const reducers = {
  [EActions.setDeviceId]: ({ payload: { deviceId } }: IActionTypes) => ({
    deviceId,
  }),
  [EActions.setWizardOpen]: ({ payload: { wizardOpen } }: IActionTypes) => ({
    wizardOpen,
  }),
  [EActions.setRecordingStartDatetime]: ({
    payload: { recordingStartDatetime },
  }: IActionTypes) => ({
    recordingStartDatetime,
  }),
  [EActions.setUploadingStartDatetime]: ({
    payload: { uploadingStartDatetime },
  }: IActionTypes) => ({
    uploadingStartDatetime,
  }),
  [EActions.setRecordingStart]: ({ payload: { stereo } }: IActionTypes) => ({
    recordingStart: true,
    introPage: false,
    stereo,
  }),
  [EActions.setUploadingStart]: () => ({
    uploadingStart: true,
    introPage: false,
  }),
  [EActions.reset]: () => ({
    ...initialState,
  }),
  [EActions.setSavingStart]: () => ({
    saving: true,
  }),
  [EActions.setSavingEnd]: () => ({
    saving: false,
  }),
  [EActions.setUploadAgain]: () => ({
    ...initialState,
    wizardOpen: true,
  }),
  [EActions.setAudioReady]: ({ payload: { audioData } }: IActionTypes) => ({
    audioReady: true,
    recordingStart: false,
    uploadingStart: false,
    audioData,
  }),
  [EActions.setPlacementsStart]: () => ({
    placementsLoading: true,
  }),
  [EActions.setPlacementsSuccess]: ({
    payload: { placements },
  }: IActionTypes) => ({
    placementsLoading: false,
    placements,
  }),
  [EActions.setPayloadPlacements]: ({
    payload: { payloadPlacements },
  }: IActionTypes) => ({
    payloadPlacements,
  }),
  [EActions.setMetaData]: (
    { payload: { metaData } }: IActionTypes,
    state: IAudioUploaderState
  ) => ({
    metaData: {
      ...state.metaData,
      ...metaData,
    },
  }),
};
export default createReducer<IAudioUploaderState>(path, initialState, reducers);
