import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { prepareActions } from "store/helpers";
import { EActions, ICustomModelsAction } from "./types";
import { AppState } from "store";
import getCustomModelOverview from "api/handlers/getCustomModelOverview";
export const path = "customModels";

const pairs = [
  [EActions.fetchCustomModelsOverviewFail, "error"],
  [EActions.fetchCustomModelsOverviewSuccess, "customModels"],
  [EActions.fetchCustomModelsRequest],
];

const actions = prepareActions<ICustomModelsAction, EActions>(pairs, path);

export default actions;

export const fetchCustomModelOverview =
  (id: number) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchCustomModelsRequest]());
    try {
      const result = await getCustomModelOverview(id);
      if (result) {
        dispatch(actions[EActions.fetchCustomModelsOverviewSuccess](result));
      }
    } catch (error) {
      dispatch(actions[EActions.fetchCustomModelsOverviewFail](error));
    }
  };
