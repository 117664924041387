import React, { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment";
import { useStyles } from "components/events/styles";
import { useDispatch } from "react-redux";
import { actions } from "store/eventModal/slice";
import { EModalTypes, ELabelDateType } from "store/eventModal/types";
import dayjs from "dayjs";
import { Box } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface IStartDatePicker {
  date: string | undefined;
  type: ELabelDateType;
  disabled?: boolean;
  maxDate?: string | any;
  minDate?: string | any;
  error: string | null;
  validateRange: any;
  modal?: string;
  end?: boolean;
  eventType?: string | null;
  label: string;
}

export const DatePicker = memo(
  ({
    disabled,
    maxDate,
    error,
    minDate,
    type,
    modal,
    validateRange,
    end,
    eventType,
    label,
    ...props
  }: IStartDatePicker) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch: any = useDispatch();

    let date = props.date;
    if (end && date) {
      const m = moment(date);
      if (m.seconds() === 59 && m.milliseconds() === 999) {
        date = m.add(1, "millisecond").format();
      }
    }

    const validateDate = useCallback(
      (date: any, valid: any, error: any) => {
        if (valid) {
          if (validateRange(date, type)) {
            dispatch(actions.setError({ type, error: null }));
          }
        } else {
          if (
            modal === EModalTypes.create &&
            type === ELabelDateType.end &&
            date === null
          ) {
            dispatch(actions.setError({ type, error: null }));
          } else {
            dispatch(actions.setError({ type, error }));
          }
        }
      },
      [dispatch, modal, type, validateRange]
    );

    const onChange = useCallback(
      (date: any) => {
        const ms = moment(date).unix();
        if (maxDate) {
          validateDate(
            date,
            ms < moment(maxDate).unix(),
            t("eventModal.errors.startAt")
          );
        } else if (minDate) {
          validateDate(
            date,
            ms > moment(minDate).unix(),
            t("eventModal.errors.endAt")
          );
        } else {
          if (validateRange(date, type)) {
            dispatch(actions.setError({ type, error: null }));
          }
        }
        if (end && date) {
          const m = moment(date);
          if (m.seconds() === 0 && m.milliseconds() === 0) {
            date = m.add(-1, "millisecond").format("YYYY-MM-DD HH:mm:ss.SSSZ");
          }
        }
        dispatch(actions.setTime({ type, time: date }));
      },
      [maxDate, minDate, validateDate, validateRange, type, dispatch, end, t]
    );

    React.useEffect(() => {
      if (validateRange(date, type)) {
        dispatch(actions.setError({ type, error: null }));
      }
    }, [eventType, date, type, validateRange, dispatch]);

    return (
      <Box
        sx={{
          "& .MuiInputBase-root": {
            paddingRight: "15px",
          },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            disabled={disabled}
            className={classes.input}
            maxDate={dayjs(maxDate)}
            minDate={dayjs(minDate)}
            label={label}
            slotProps={{
              actionBar: {
                actions: ["clear", "accept"],
              },
              textField: {
                variant: "outlined",
                error: !!error,
                helperText: !!error ? error : null,
              },
            }}
            slots={{
              openPickerIcon: AccessTimeIcon,
            }}
            value={date ? dayjs(date) : null}
            onChange={onChange}
          />
        </LocalizationProvider>
      </Box>
    );
  }
);
