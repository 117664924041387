import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import i18next from "i18next";
import moment from "moment";

import getEvents from "api/handlers/event/getEvents";
import getEventTypes from "api/handlers/event/getEventTypes";
import patchEvent from "api/handlers/event/patchEvent";
import massResolveEvents from "api/handlers/event/massResolveEvents";
import getAlertSeverities from "api/handlers/getAlertSeverities";
import setAsMaster from "api/handlers/event/setAsMaster";
import patchDeviceConfig from "api/handlers/device/patchDeviceConfig";

import { prepareActions } from "store/helpers";
import { ensure, uniqueArray, cmp } from "shared/helpers";
import { groupEventTypes } from "shared/eventTypes";
import { EActions, IEventsAction, IFetchEventsParams } from "./types";
import { AppState } from "store";
import notifierActions from "store/notifier/actions";

import { IEventList, IEventTypeList, TAlertSeverity } from "types/event";
import { statuses } from "shared/eventStatuses";
import placementActions from "store/machineDetail/placements/actions";
import { EActions as EPlacementActions } from "store/machineDetail/placements/types";
import { EGroupedTypes } from "shared/eventTypes";

const { enqueueSnackbar } = notifierActions;

export const path = "events";

const pairs = [
  [EActions.fetchEventsRequest, "timestamp"],
  [EActions.fetchEventsRequestSilent, "timestamp"],
  [EActions.fetchEventsFail, "error"],
  [
    EActions.fetchEventsSuccess,
    "events",
    "eventTypes",
    "eventsFetchParams",
    "unresolvedEvents",
    "timestamp",
  ],
  [EActions.updateEventsLocal, "event"],
  [EActions.setResolveIds, "resolveIds"],
  [EActions.setIdsToResolve, "idsToResolve"],
  [EActions.setPage, "pageIndex"],
  [EActions.setSort, "sortByState"],
  [EActions.setFilter, "filters"],
  [EActions.openMasterAlert, "id"],
  [EActions.closeMasterAlert, "id"],
  [EActions.setReset],
  [EActions.updateMasterAlert, "alertIds", "masterAlertId", "groupedAlerts"],
];

export const actions = prepareActions<IEventsAction, EActions>(pairs, path);
export default actions;

export const massResolve =
  (
    type: string,
    data: { note: string; severity: any; related_pair_event?: number }
  ) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const { id } = getState().machineDetail.machine.machine;
      const { resolveIds } = getState().events;

      let payload = {
        machineId: id,
        resolveIds: undefined,
        ...data,
      };

      if (type === "page") {
        payload.resolveIds = resolveIds;
      }

      await massResolveEvents(payload);
      dispatch(refetchEventsSilent());
      dispatch(
        enqueueSnackbar(i18next.t(`machine.card.resolve.modal.${type}.success`))
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar(i18next.t(`machine.card.resolve.modal.${type}.fail`))
      );
    }
  };

export const updateEvent =
  ({ id, payload }: { id: number; payload: { [key: string]: any } }) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any | any) => {
    try {
      const data = await patchEvent({ id, payload });
      dispatch(actions.updateEventsLocal(data));
      dispatch(refetchEventsSilent());
    } catch (err) {
      dispatch(enqueueSnackbar(i18next.t("machine.event.updateError")));
    }
  };

const prepareEventIdForApiRequest = (input: string) => {
  return [parseInt(input)];
};

export const regroupEvents = (events: any[]) => {
  const groupedEventsByMasterAlert = events
    .filter((i: any) => i.masterAlert)
    .reduce(
      (acc: any, cur: any) => ({
        ...acc,
        [cur.masterAlert]: (acc[cur.masterAlert] || []).concat([cur]),
      }),
      {}
    );

  const newEvents: any = [];
  events
    .filter((i: any) => !i.masterAlert)
    .forEach((i: any) => {
      const eventsToAdd = groupedEventsByMasterAlert[i.id];
      if (eventsToAdd) {
        eventsToAdd
          .sort((a: any, b: any) => cmp((i: any) => i.createdAt)(b, a))
          .forEach((j: any) => {
            newEvents.push(j);
          });
      }
      newEvents.push(i);
    });

  return newEvents;
};

const getEventsWithGroupedAlerts = async (params: any) => {
  let events = await getEvents({
    ...params,
    hasMasterAlert: params.ids && params.ids.length ? undefined : false,
    preventCancel: true,
  });

  if (!events) {
    return events;
  }

  if (params.ids) {
    events.results.forEach((i: any) => {
      if (params.ids.indexOf(i.id) !== -1) {
        i.masterAlert = null;
      }
    });
  }

  const masterAlertIds = events.results
    .filter((i: any) => i.groupedAlerts && i.groupedAlerts.length)
    .map((i: any) => i.id)
    .filter((i: any) => i);

  if (!masterAlertIds.length) {
    return events;
  }

  let groupedEvents = await getEvents({
    page: 1,
    pageSize: 99999,
    groupedAlerts: masterAlertIds,
    preventCancel: true,
  });

  if (!groupedEvents) {
    return groupedEvents;
  }

  events.results = regroupEvents(events.results.concat(groupedEvents.results));
  return events;
};

const fillEvents = (
  results: IEventList[],
  types: IEventTypeList[],
  alertSeverities: TAlertSeverity[]
) =>
  results.map((event: IEventList) => {
    const type = ensure(
      types.find((type: IEventTypeList) => type.codename === event.type)
    );
    let relatedType;
    if (event.relatedEvent) {
      relatedType = ensure(
        types.find(
          (type: IEventTypeList) => type.codename === event.relatedEvent?.type
        )
      );
    }
    let severity;
    if (event.alert_severity) {
      severity = ensure(
        alertSeverities.find(
          (item: TAlertSeverity) => item.codename === event.alert_severity
        )
      );
    }
    return {
      ...event,
      type: type,
      createdAt: new Date(event.createdAt),
      alert_severity: event.alert_severity,
      relatedEvent: event.relatedEvent
        ? {
            ...event.relatedEvent,
            type: relatedType,
            createdAt: new Date(event.relatedEvent.createdAt),
          }
        : null,
    };
  });

export const fetchEvents =
  ({ page, pageSize, machine, filters, sortByState }: IFetchEventsParams) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    const timestamp = Date.now();
    dispatch(actions[EActions.fetchEventsRequest](timestamp));
    try {
      let events = await getEventsWithGroupedAlerts({
        page,
        pageSize,
        orderBy: sortByState,
        machine,
        eventTypes: filters?.type,
        notEventTypes:
          filters?.type && filters.type.length === 0
            ? [EGroupedTypes.running]
            : [],
        status: filters?.status
          ? uniqueArray(
              filters.status.map((i: string | null) =>
                i ? statuses[i].filter.status : null
              )
            )
          : undefined,
        isCritical: filters?.status
          ? filters.status.map((i: string | null) =>
              i ? statuses[i].filter.is_critical : null
            )
          : undefined,
        alertSeverity: filters?.status
          ? filters.status.map((i: string | null) =>
              i ? statuses[i].filter.alert_severity : null
            )
          : undefined,
        communicationStatus: filters?.communicationStatus,
        timeIntervals: { from: filters?.from, to: filters?.to },
        ids: filters?.id ? prepareEventIdForApiRequest(filters?.id) : undefined,
        q: filters?.q,
      });
      if (!events) {
        return;
      }
      dispatch(
        actions[EActions.setIdsToResolve]([
          ...events?.results
            .filter(
              (event: IEventList) =>
                event.status !== "resolved" &&
                (event.type === "anomaly" || event.type === "anomaly_warning")
            )
            .map((event: IEventList) => event.id),
        ])
      );
      dispatch(
        actions[EActions.setResolveIds]([
          ...events?.results
            ?.filter(
              (event: IEventList) =>
                event.type === "anomaly" || event.type === "anomaly_warning"
            )
            .map((event: IEventList) => event.id),
        ])
      );
      const types = await getEventTypes();
      const alertSeverities = await getAlertSeverities();
      dispatch(
        actions[EActions.fetchEventsSuccess](
          {
            ...events,
            results: fillEvents(events?.results, types, alertSeverities),
          },
          groupEventTypes(types),
          {
            page,
            pageSize,
            orderBy: sortByState,
            machine,
            eventTypes: filters?.type,
            status: filters?.status,
            communicationStatus: filters?.communicationStatus,
          },
          events?.unresolved,
          timestamp
        )
      );
    } catch (error) {
      dispatch(actions[EActions.fetchEventsFail](error));
    }
  };

export const refetchEventsSilent =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const timestamp = Date.now();
      dispatch(actions[EActions.fetchEventsRequestSilent](timestamp));
      const {
        eventsFetchParams,
        filters: { from, to, type, id },
      } = getState().events;
      if (!eventsFetchParams.machine) {
        return;
      }
      const parsedId = id ? prepareEventIdForApiRequest(id) : undefined;
      const events = await getEventsWithGroupedAlerts({
        ...eventsFetchParams,
        notEventTypes: type && type.length === 0 ? [EGroupedTypes.running] : [],
        eventTypes: eventsFetchParams?.eventTypes,
        timeIntervals: { from, to },
        ids: parsedId,
      });
      if (!events) {
        return;
      }
      dispatch(
        actions[EActions.setIdsToResolve]([
          ...events?.results
            .filter(
              (event: IEventList) =>
                event.status !== "resolved" &&
                (event.type === "anomaly" || event.type === "anomaly_warning")
            )
            .map((event: IEventList) => event.id),
        ])
      );
      dispatch(
        actions[EActions.setResolveIds]([
          ...events?.results
            ?.filter(
              (event: IEventList) =>
                event.type === "anomaly" || event.type === "anomaly_warning"
            )
            .map((event: IEventList) => event.id),
        ])
      );
      const types = await getEventTypes();
      const alertSeverities = await getAlertSeverities();
      dispatch(
        actions[EActions.fetchEventsSuccess](
          {
            ...events,
            results: fillEvents(events?.results, types, alertSeverities),
          },
          groupEventTypes(types),
          eventsFetchParams,
          events?.unresolved,
          timestamp
        )
      );
    } catch (error) {
      dispatch(actions[EActions.fetchEventsFail](error));
    }
  };

export const openMasterAlert =
  (id: number) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any | any) =>
    dispatch(actions[EActions.openMasterAlert](id));

export const closeMasterAlert =
  (id: number) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any | any) =>
    dispatch(actions[EActions.closeMasterAlert](id));

export const groupUngroupAlerts =
  (
    masterAlertId: number,
    alertIds?: number[],
    all?: boolean,
    ungroup?: boolean
  ) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any | any) => {
    const result = await setAsMaster({
      masterAlertId,
      alertIds: all ? undefined : alertIds,
      reset: ungroup,
    });
    if (!result) return;
    const { groupedAlerts } = result;
    const types = await getEventTypes();
    const alertSeverities = await getAlertSeverities();
    dispatch(
      actions[EActions.updateMasterAlert](
        alertIds,
        masterAlertId,
        fillEvents(groupedAlerts, types, alertSeverities)
      )
    );
    dispatch(
      enqueueSnackbar(
        i18next.t(
          ungroup
            ? "events.ungroupAlerts.success"
            : "events.groupAlerts.success"
        )
      )
    );
    dispatch(refetchEventsSilent());
  };

export const groupAlerts =
  (masterAlertId: number, alertIds: number[], all?: boolean) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any | any) =>
    dispatch(groupUngroupAlerts(masterAlertId, alertIds, all));

export const ungroupAlerts =
  (masterAlertId: number, alertIds?: number[]) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any | any) =>
    dispatch(groupUngroupAlerts(masterAlertId, alertIds, undefined, true));

export const snoozePlacements =
  (placements: number[], hours: number, callback?: any) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any | any) => {
    const date = hours
      ? moment(new Date())
          .add(hours, "hours")
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      : null;
    await patchDeviceConfig({
      placements: placements.map((i: number) => ({
        id: i,
        suppress_notifications_until: date,
      })),
    });
    if (callback) {
      callback();
    }
    placements.forEach((i: number) =>
      dispatch(
        placementActions[EPlacementActions.setSuppressNotificationsUntil](
          i,
          date
        )
      )
    );
    dispatch(
      enqueueSnackbar(
        i18next.t(date ? "events.snoozeSuccess" : "events.unsnoozeSuccess")
      )
    );
  };
