import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { prepareActions } from "store/helpers";
import getMachine from "api/handlers/machine/getMachine";
import getMachineModels from "api/handlers/machine/getMachineModels";
import getMachineProductionModes from "api/handlers/machine/getMachineProductionModes";
import getMachineImage from "api/handlers/machine/getMachineImage";
import patchMachine from "api/handlers/machine/patchMachine";
import getFacility from "api/handlers/facility/getFacility";
import getProject from "api/handlers/project/getProject";
import getUsers from "api/handlers/user/getUsers";
import updateFacility from "api/handlers/facility/updateFacility";
import { EActions, IMachineDetailAction } from "./types";
import { AppState } from "store";
import notifier from "store/notifier/actions";
import i18n from "localization";
import eventActions from "store/events/actions";
import placementsActions from "./placements/actions";
import addMachineImage from "api/handlers/machine/addMachineImage";
import updateMachineImage from "api/handlers/machine/updateMachineImage";
import removeMachineImage from "api/handlers/machine/removeMachineImage";
import { getPlacement } from "api/handlers/placement/getPlacement";
export const path = "machineDetail/machine";

const pairs = [
  [EActions.FETCH_MACHINE_REQUEST],
  [EActions.FETCH_MACHINE_FAIL, "error"],
  [EActions.FETCH_MACHINE_SUCCESS, "machine", "facility", "users"],
  [EActions.FETCH_FACILITY_SUCCESS, "facility"],
  [EActions.FETCH_MACHINE_IMAGE_REQUEST],
  [EActions.FETCH_MACHINE_IMAGE_FAIL, "error"],
  [EActions.FETCH_MACHINE_IMAGE_SUCCESS, "machineImage"],
  [EActions.FETCH_MACHINE_FULL_IMAGE_REQUEST],
  [EActions.FETCH_MACHINE_FULL_IMAGE_FAIL, "error"],
  [EActions.FETCH_MACHINE_FULL_IMAGE_SUCCESS, "machineFullImage"],
  [EActions.FETCH_MACHINE_MODELS_REQUEST],
  [EActions.FETCH_MACHINE_MODELS_FAIL, "error"],
  [EActions.FETCH_MACHINE_MODELS_SUCCESS, "models"],
  [EActions.FETCH_MACHINE_PRODUCTION_MODES_REQUEST],
  [EActions.FETCH_MACHINE_PRODUCTION_MODES_FAIL, "error"],
  [EActions.FETCH_MACHINE_PRODUCTION_MODES_SUCCESS, "productionModes"],
  [EActions.setReset],
];

const actions = prepareActions<IMachineDetailAction, EActions>(pairs, path);

export default actions;

export const fetchMachineDetail =
  (id: number, silent = false) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    !silent && dispatch(actions[EActions.FETCH_MACHINE_REQUEST]());
    try {
      const response = await getMachine(id);
      if (response) {
        const facility = await getFacility(response.facility);
        if (facility) {
          const project = await getProject(facility.project);
          if (project) {
            const users = await getUsers({
              page: 1,
              pageSize: 9999,
              orderBy: [],
              ids: project.users,
            });
            if (users) {
              dispatch(
                actions[EActions.FETCH_MACHINE_SUCCESS](
                  response,
                  facility,
                  users.results
                )
              );
            }
          }
        }
      }
    } catch (error: any) {
      dispatch(actions[EActions.FETCH_MACHINE_FAIL](error));
    }
  };

export const fetchMachineModels =
  (id: number, silent = false) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    !silent && dispatch(actions[EActions.FETCH_MACHINE_MODELS_REQUEST]());
    try {
      const response = await getMachineModels({ id });
      if (response) {
        dispatch(actions[EActions.FETCH_MACHINE_MODELS_SUCCESS](response));
      }
    } catch (error: any) {
      dispatch(actions[EActions.FETCH_MACHINE_MODELS_FAIL](error));
    }
  };

export const fetchMachineDetailViaPlacement =
  (id: number, silent = false) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    !silent && dispatch(actions[EActions.FETCH_MACHINE_REQUEST]());
    try {
      const { data }: any = await getPlacement(id);
      if (data.machine) {
        const response = await getMachine(data.machine);
        if (response) {
          const facility = await getFacility(response.facility);
          if (facility) {
            const project = await getProject(facility.project);
            if (project) {
              const users = await getUsers({
                page: 1,
                pageSize: 9999,
                orderBy: [],
                filterObject: { id: project.users },
              });
              if (users) {
                dispatch(
                  actions[EActions.FETCH_MACHINE_SUCCESS](
                    response,
                    facility,
                    users.results
                  )
                );
              }
            }
          }
        }
      }
    } catch (error: any) {
      dispatch(actions[EActions.FETCH_MACHINE_FAIL](error));
    }
  };

export const fetchMachineImage =
  (id: number, silent = false) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    !silent && dispatch(actions[EActions.FETCH_MACHINE_IMAGE_REQUEST]());
    try {
      const response = await getMachineImage(id);
      if (response) {
        dispatch(actions[EActions.FETCH_MACHINE_IMAGE_SUCCESS](response));
      }
    } catch (err: any) {
      dispatch(actions[EActions.FETCH_MACHINE_IMAGE_FAIL](err));
    }
  };

export const fetchMachineFullImage =
  (id: number, silent = false) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    !silent && dispatch(actions[EActions.FETCH_MACHINE_FULL_IMAGE_REQUEST]());
    try {
      const response = await getMachineImage(id, true);
      if (response) {
        dispatch(actions[EActions.FETCH_MACHINE_FULL_IMAGE_SUCCESS](response));
      }
    } catch (err: any) {
      dispatch(actions[EActions.FETCH_MACHINE_FULL_IMAGE_FAIL](err));
    }
  };

export const fetchMachineProductionModes =
  (id: number, silent = false) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    !silent &&
      dispatch(actions[EActions.FETCH_MACHINE_PRODUCTION_MODES_REQUEST]());
    try {
      const response = await getMachineProductionModes({ id });
      if (response) {
        dispatch(
          actions[EActions.FETCH_MACHINE_PRODUCTION_MODES_SUCCESS](response)
        );
      }
    } catch (error: any) {
      dispatch(actions[EActions.FETCH_MACHINE_PRODUCTION_MODES_FAIL](error));
    }
  };

export const addImage =
  (machineId: number, imageData: any) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      await dispatch(actions[EActions.FETCH_MACHINE_IMAGE_REQUEST]());
      await addMachineImage({
        machine: machineId,
        version: Math.random().toString().substr(2, 10),
        type: "FULL",
        image_data: imageData,
      });

      dispatch(
        notifier.enqueueSnackbar(i18n.t(`machine.detail.image.upload.success`))
      );
      dispatch(fetchMachineImage(machineId));
    } catch (error: any) {
      dispatch(
        notifier.enqueueSnackbar(i18n.t(`machine.detail.image.upload.fail`))
      );
    }
  };

export const changeImage =
  (machineId: number, imageId: number, imageData?: any) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      await dispatch(actions[EActions.FETCH_MACHINE_IMAGE_REQUEST]());
      if (imageData) {
        await updateMachineImage(imageId, {
          version: Math.random().toString().substr(2, 10),
          type: "FULL",
          image_data: imageData,
        });
      } else {
        await removeMachineImage(imageId);
      }
      dispatch(
        notifier.enqueueSnackbar(
          i18n.t(
            `machine.detail.image.${imageData ? "upload" : "delete"}.success`
          )
        )
      );
      dispatch(fetchMachineImage(machineId));
    } catch (error: any) {
      dispatch(
        notifier.enqueueSnackbar(
          i18n.t(`machine.detail.image.${imageData ? "upload" : "delete"}.fail`)
        )
      );
    }
  };

export const renameMachine =
  (id: number, name: string) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      const response = await patchMachine({ id, payload: { name } });
      if (response) {
        dispatch(fetchMachineDetail(id, true));
        dispatch(
          notifier.enqueueSnackbar(i18n.t("machine.detail.rename.success"))
        );
      }
    } catch (error: any) {
      if (error.message === "duplicate_name") {
        return dispatch(
          notifier.enqueueSnackbar(
            i18n.t("machine.detail.rename.failDuplicate")
          )
        );
      }
      return dispatch(
        notifier.enqueueSnackbar(i18n.t("machine.detail.rename.fail"))
      );
    }
  };

export const updateMachineFacility =
  (machineId: number, facilityId: number, users: number[]) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      const response = await updateFacility({
        id: facilityId,
        users_to_notify: users,
      });
      if (response) {
        await dispatch(actions[EActions.FETCH_FACILITY_SUCCESS](response));
      }
    } catch (error: any) {
      return dispatch(
        notifier.enqueueSnackbar(i18n.t("facilities.detail.error"))
      );
    }
  };

export const resetMachine =
  () => async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions.setReset());
    dispatch(eventActions.setReset());
    dispatch(placementsActions.setReset());
  };
