import React, { memo } from "react";
import Box from "@mui/material/Box";
import Select from "components/select";
import { Field } from "react-final-form";
import { useStyles } from "components/events/styles";
import useListCurrencies from "dataHooks/currencies/list";
import { ICurrencyList } from "types/currency";

interface IEventTypeSelect {
  currency: string | undefined;
  setCurrency: (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;

  currencySelectId: string;
  selectLabel: string;
}

const CurrencySelect = memo(
  ({
    currency,
    setCurrency,
    currencySelectId,
    selectLabel,
  }: IEventTypeSelect) => {
    const classes = useStyles();

    const { isFetching, currencies } = useListCurrencies();

    const options = currencies
      ? currencies?.results.map((currency: ICurrencyList) => {
          return {
            key: currency?.id,
            value: currency?.codename,
            text: currency?.codename,
          };
        })
      : [];

    return (
      <Box className={classes.select}>
        {isFetching ? null : (
          <Field
            id={currencySelectId}
            name={currencySelectId}
            component={Select}
            label={selectLabel}
            options={!isFetching && options}
            onChange={setCurrency}
            value={currency}
          />
        )}
      </Box>
    );
  }
);

export default CurrencySelect;
