import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { getDeviceNameWithPrefix } from "shared/helpers";
import { IDeviceList } from "types/device";

export default async function getDevice(id: number) {
  const url = endpoints.devices.detail(id);
  try {
    const response = await axiosNeuron.get(url);
    const data: IDeviceList = {
      id: response.data.id,
      name: getDeviceNameWithPrefix(response.data.name),
      note: response.data.note,
      internetConnection: response.data.internet_connection,
      version: response.data.version,
      telephoneNumber: response.data.telephone_number,
      carrier: response.data.carrier,
      hasSdcard: response.data.has_sdcard,
      macAddress: response.data.mac_address,
      hasHdd: response.data.has_hdd,
      serialUid: response.data.serial_uid,
      status: response.data.status,
      project: response.data.project,
      channels: response.data.channels,
      channelCount: response.data.channel_count,
      assignedChannelCount: response.data.assigned_channel_count,
      assignedPlacements: response.data.assigned_placements,
      facility: response.data.facility,
      company: response.data.company,
      machines: response.data.machines,
      recordingPlan: response.data.recording_plan,
      edgeDiscardAudio: response.data.edge_discard_audio,
    };
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
