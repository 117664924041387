import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { IEventList } from "types/event";

interface IEventParams {
  id: number;
}

export default async function getEvent({
  id,
}: IEventParams): Promise<IEventList | undefined> {
  const url = endpoints.events.detail(id);

  try {
    const { data } = await axiosNeuron.get(`${url}`);

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
