import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
  memo,
} from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import Button from "components/button";
import { BlackCheckbox } from "components/checkbox";
import Tag from "components/tag";
import Spinner from "components/spinner";
import isEqual from "lodash/isEqual";
import Search from "./search";
import xor from "lodash/xor";
import clsx from "clsx";
import sortBy from "lodash/sortBy";
import groupBy from "lodash/groupBy";
import Divider from "components/typography/heading/divider";
import { Typography } from "@mui/material";

interface Option {
  value: string | number;
  text: string;
  sortPriority?: number;
  classes?: any;
  onChange?: (
    name: string | number,
    value: boolean,
    setSelectedValues: any
  ) => void;
  hideTag?: boolean;
}

interface Group {
  id: string | null;
  name?: string;
}

interface GroupedOptions extends Group {
  options: Option[];
}

type TItem = {
  checked: boolean;
  selectedValue: string | number;
  text: string;
  disabled: boolean;
  onchange: (e: string | number) => void;
  icon?: React.ReactElement;
  classes?: any;
  customOnChange?: (value: boolean) => void;
};

interface Props extends FormControlProps {
  initialValue?: Array<string | number>;
  searchPlaceholder: string;
  id: string;
  name: string;
  label: string;
  options: Option[];
  confirmButtonLabel: string;
  deselectButtonLabel: string;
  disabled: boolean;
  loading: boolean;
  onChangeSubmit: (id: number[]) => void;
  loadMore?: () => void;
  loadingMore?: boolean;
  onSearch?: (val: string) => void;
  multiple?: boolean;
  disabledOptions?: (string | number)[];
  highlightedKeysWithIcons?: { key: string; icon: React.ReactElement }[];
  bigSize?: boolean;
  groups?: Group[];
}

const Item = memo(
  ({
    selectedValue,
    checked,
    text,
    onchange,
    disabled,
    icon,
    ...props
  }: TItem) => {
    const classes = useStyles();

    const onClick = useCallback(() => {
      onchange(selectedValue);
      if (props.customOnChange) {
        props.customOnChange(!checked);
      }
    }, [onchange, selectedValue, props.customOnChange, checked]);

    return (
      <MenuItem
        {...props}
        onClick={onClick}
        disabled={disabled}
        dense
        className={classes.menuItem}
        classes={props.classes}
        data-value={selectedValue}
      >
        <BlackCheckbox checked={checked} />
        <ListItemText
          primary={
            <>
              {<span className={classes.iconOuter}>{icon}</span>} {text}
            </>
          }
        />
      </MenuItem>
    );
  },
  (prev, next) => {
    return !(prev.checked !== next.checked);
  }
);

const filterOptions = (
  options: Option[],
  searchPhrase: string,
  groups?: Group[]
) => {
  const groupedOptions = Object.entries(groupBy(options, "group"));

  const outputOptions: GroupedOptions[] = sortBy(
    groupedOptions.map((option: any) => {
      const existingGroup = groups?.find(
        (group: any) => group.id?.toString() === option[0]
      )?.name;
      return {
        id: option[0],
        name: existingGroup || undefined,
        options: [],
        sortPriority: existingGroup ? 1 : 0,
      };
    }),
    ["sortPriority", "name"]
  );

  groupedOptions.forEach((group: any) => {
    const categoryId = group[0];
    const categoryOptions = group[1];
    const outputOptionsPreparedGroup = outputOptions.find(
      ({ id }) => id === categoryId
    );
    if (
      outputOptionsPreparedGroup?.name
        ?.toLowerCase()
        .includes(searchPhrase.toLowerCase())
    ) {
      outputOptionsPreparedGroup?.options.push(...categoryOptions);
    }
    categoryOptions.forEach((option: any) => {
      if (option?.text.toLowerCase().includes(searchPhrase.toLowerCase())) {
        if (
          !outputOptionsPreparedGroup?.options?.find(
            ({ value }) => value === option?.value
          )
        ) {
          outputOptionsPreparedGroup?.options.push(option);
        }
      }
    });
  });
  return outputOptions;
};

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      width: "100%",
      "& .MuiSelect-select": {
        height: "auto",
        minHeight: theme.typography.pxToRem(20),
        paddingTop: theme.typography.pxToRem(9),
        paddingBottom: theme.typography.pxToRem(9),
      },
      "& .MuiInputLabel-root:not(.MuiInputLabel-shrink)": {
        fontSize: "inherit",
      },
    },
    rootBigSize: {
      "& .MuiInputBase-root": {
        position: "relative",
        top: theme.typography.pxToRem(0),
      },
      "& .MuiSelect-root": {
        paddingTop: theme.typography.pxToRem(15),
        paddingBottom: theme.typography.pxToRem(14),
      },
      "& .MuiInputLabel-outlined.MuiInputLabel-marginDense:not(.MuiInputLabel-shrink)":
        {
          transform: "translate(18px, 16px) scale(1)",
        },
    },
    searchIcon: {
      color: theme.custom.palette.placeholder,
      cursor: "pointer",
    },
    groupHeader: {
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    textField: {
      height: theme.spacing(4),
      padding: 100,
    },
    searchInput: {
      fontSize: theme.custom.typography.fontSize[14],
      fontWeight: theme.custom.typography.fontWeight.normal,
      color: theme.custom.palette.data,
      letterSpacing: theme.custom.typography.letterSpacing.small,
      "&::placeholder": {
        fontSize: theme.custom.typography.fontSize[14],
        fontWeight: theme.custom.typography.fontWeight.normal,
        color: theme.custom.palette.placeholder,
        letterSpacing: theme.custom.typography.letterSpacing.small,
      },
    },
    searchBox: {
      boxShadow: `0 ${theme.spacing(0.5)} ${theme.spacing(
        0.5
      )} -${theme.spacing(0.5)} ${theme.custom.palette.shadow}`,
    },
    buttonsBox: {
      boxShadow: `0 -${theme.spacing(0.5)} ${theme.spacing(
        0.5
      )} -${theme.spacing(0.5)} ${theme.custom.palette.shadow}`,
    },
    menuItem: {
      padding: 0,
      marginRight: theme.spacing(2),
      whiteSpace: "pre-wrap",
    },
    loadMoreItem: {
      display: "flex",
      justifyContent: "center",
    },
    renderWrap: {
      display: "flex",
      flexWrap: "wrap",
      marginBottom: theme.spacing(-1.25),
    },
    renderWrapBox: {
      maxWidth: "100%",
    },
    last: { maxWidth: "50%" },
    andMoreText: {
      maxWidth: "30%",
      color: theme.custom.palette.label,
      fontFamily: theme.custom.typography.fontFamily,
      fontSize: theme.custom.typography.fontSize["11"],
      fontWeight: "bold",
      display: "inline-block",
      padding: ".1875rem .5rem .125rem .25rem",
      textTransform: "uppercase",
      letterSpacing: ".04rem",
      lineHeight: 1.2,
    },
    separator: {
      height: 1,
      display: "block",
      background: theme.custom.palette.placeholder,
      margin: `${theme.spacing(1)} ${theme.spacing(2.25)}`,
    },
    iconOuter: {
      float: "left",
      height: theme.typography.pxToRem(14),
      position: "relative",
      top: theme.typography.pxToRem(-3),
      left: theme.typography.pxToRem(-5),
      marginRight: theme.typography.pxToRem(2),
    },
    [theme.breakpoints.down(600)]: {
      label: {
        padding: 0,
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "4rem",
      },
    },
    [theme.breakpoints.down(450)]: {
      label: {
        width: "2rem",
      },
    },
  };
});

const SearchMultiSelect: React.FC<Props> = (props: any) => {
  const {
    initialValue,
    id,
    label,
    disabledOptions,
    options,
    confirmButtonLabel,
    deselectButtonLabel,
    disabled,
    loading,
    onChangeSubmit,
    searchPlaceholder,
    loadMore,
    loadingMore,
    onSearch,
    multiple = true,
    highlightedKeysWithIcons,
    bigSize,
    groups,
  } = props;

  const inputLabel = useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);
  const loadMoreFnRef = useRef<(val: string) => void | null>();

  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedValues, setSelectedValues] = useState<Array<string | number>>(
    initialValue || []
  );

  const [searchPhrase, setSearchPhrase] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth);
  }, []);

  useEffect(() => {
    if (loadMore) {
      loadMoreFnRef.current = loadMore;
    }
  }, [loadMore]);

  useEffect(() => {
    setSelectedValues(initialValue || []);
  }, [initialValue]);

  const filteredOptions = useMemo(() => {
    return filterOptions(options, searchPhrase, groups);
  }, [onSearch, options, searchPhrase, groups]);

  const resetState = useCallback(
    (selectedValues: any) => {
      if (!isEqual(selectedValues, initialValue)) {
        onChangeSubmit(selectedValues as Array<number>);
      }
      setIsOpen(false);
    },
    [initialValue, onChangeSubmit]
  );

  const handleChange = useCallback(
    (value: string | number) => {
      if (!multiple) {
        setSelectedValues((prev: any) => {
          if (prev.indexOf(value) > -1) {
            return [];
          } else {
            return [value];
          }
        });
      } else {
        setSelectedValues((prev: any) => xor(prev, [value]));
      }
    },
    [multiple]
  );

  const renderTags = useCallback(
    (selected: any) => {
      const MAX_ITEM_COUNT = 2;
      const usedOptions: any = (selected as string[])
        .map((selectedValue: any) => {
          for (let i = 0; i < options.length; i++) {
            if (options[i].value === selectedValue) {
              if (options[i].hideTag) {
                return null;
              } else {
                return options[i].text;
              }
            }
          }
          return null;
        })
        .filter((e: any) => e);

      const tagsToFit = usedOptions.reduce(
        (array: string[], option: string) => {
          if (array.length < MAX_ITEM_COUNT) {
            array.push(option);
          }
          return array;
        },
        [] as string[]
      );

      return (
        <div className={classes.renderWrap}>
          {(tagsToFit as string[]).map((option, index) => {
            return (
              <Box
                mr={1}
                mb={bigSize ? 1 : 1.5}
                key={index}
                className={clsx(
                  classes.renderWrapBox,
                  tagsToFit.length - 1 === index &&
                    tagsToFit.length >= MAX_ITEM_COUNT &&
                    classes.last
                )}
              >
                <Tag color={false} ellipsis>
                  {option}
                </Tag>
              </Box>
            );
          })}
          {usedOptions > tagsToFit ? (
            <Box mr={1} mb={1.5} className={classes.andMoreText}>
              {t("multiselect.andMore", {
                postProcess: "interval",
                count: usedOptions.length - tagsToFit.length,
              })}
            </Box>
          ) : null}
        </div>
      );
    },
    [classes.renderWrap, classes.renderWrapBox, options]
  );

  const highlightedOptions =
    highlightedKeysWithIcons &&
    filteredOptions[0]?.options.filter(({ value }: { value: any }) =>
      highlightedKeysWithIcons.find(
        ({ key }: { key: any }) => key === value.toString()
      )
    );

  const nonHighlightedOptionsAvailable = highlightedOptions
    ? filteredOptions[0]?.options.length - highlightedOptions.length > 0
    : false;

  const renderOptions = useMemo(
    () =>
      !isOpen
        ? null
        : filteredOptions.map((group: any) => {
            return (
              <>
                {group.options.length > 0 && group.name && (
                  <div
                    className={classes.groupHeader}
                    onClick={(e: any) => e.stopPropagation()}
                  >
                    <Divider line noMargin key={group.name}>
                      {group.name}
                    </Divider>
                  </div>
                )}
                {group.options.map((option: any) => {
                  return (
                    <>
                      <Item
                        onchange={handleChange}
                        key={option?.value}
                        selectedValue={option?.value}
                        text={option?.text}
                        classes={option?.classes}
                        customOnChange={(value: boolean) =>
                          option.onChange
                            ? option.onChange(
                                option.value,
                                value,
                                setSelectedValues
                              )
                            : null
                        }
                        disabled={
                          disabledOptions
                            ? disabledOptions?.indexOf(option.value) > -1
                            : false
                        }
                        checked={selectedValues.indexOf(option.value) !== -1}
                        icon={
                          highlightedKeysWithIcons?.find(
                            ({ key }: { key: any }) => key === option?.value
                          )?.icon
                        }
                      />
                      {highlightedOptions &&
                        highlightedOptions[highlightedOptions.length - 1]
                          ?.value === option.value &&
                        nonHighlightedOptionsAvailable && (
                          <div className={classes.separator} />
                        )}
                    </>
                  );
                })}
              </>
            );
          }),
    [filteredOptions, handleChange, isOpen, selectedValues]
  );

  return (
    <FormControl
      variant="outlined"
      className={clsx(classes.root, bigSize && classes.rootBigSize)}
      size="small"
    >
      <InputLabel className={classes.label} ref={inputLabel} htmlFor={id}>
        <Typography noWrap> {label}</Typography>
      </InputLabel>
      <Select
        id={id}
        labelId={id}
        label={label}
        disabled={disabled}
        multiple={multiple}
        value={selectedValues}
        renderValue={renderTags}
        open={isOpen}
        onOpen={() => {
          setIsOpen(true);
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          onClose: () => resetState(selectedValues),
        }}
      >
        <Search
          id={id}
          searchPlaceholder={searchPlaceholder}
          onSearch={onSearch}
          setSearchPhrase={setSearchPhrase}
        />
        {loading ? <Spinner /> : renderOptions}
        {loadMore &&
          (loadingMore ? (
            <Spinner />
          ) : (
            <MenuItem key={`loadMore-${id}`} className={classes.loadMoreItem}>
              <Button onClick={loadMore}>{t("loadMore")}</Button>
            </MenuItem>
          ))}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          p={1}
          pb={0.5}
          pt={1.5}
          mt={2}
          className={classes.buttonsBox}
        >
          <Button
            disabled={selectedValues.length === 0}
            onClick={(e: any) => {
              e.stopPropagation();
              setSelectedValues([]);
            }}
          >
            {deselectButtonLabel}
          </Button>
          <Button onClick={() => resetState(selectedValues)}>
            {confirmButtonLabel}
          </Button>
        </Box>
      </Select>
    </FormControl>
  );
};

export default SearchMultiSelect;
