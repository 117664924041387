import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";

import { EActions, IActionTypes, EnvTypes } from "./types";
import { AppState } from "store";
import { prepareActions } from "store/helpers";

import notifierActions from "store/notifier/actions";
import i18n from "localization";
import trainModel from "api/handlers/trainModel";

export const path = "machineDetail/oneClickTraining";

const actionData = [
  [EActions.setModalOpen, "modalOpen"],
  [EActions.setLoading, "loading"],
  [EActions.setEnv, "environment"],
  [EActions.setOtherEnv, "otherEnv"],
  [EActions.setTrainingJobTag, "trainingJobTag"],
  [EActions.setXTraceId, "traceId"],
];

const actions = prepareActions<IActionTypes, EActions>(actionData, path);

export default actions;

export const triggerTraining =
  (machineId: number) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any | any,
    getState: any
  ) => {
    try {
      const { environment, otherEnv, trainingJobTag, traceId } =
        getState().machineDetail.oneClickTraining;
      dispatch(actions.setLoading(true));
      const train = await trainModel({
        machineId,
        traceId,
        environment: environment === EnvTypes.other ? otherEnv : environment,
        trainingJobTag:
          environment !== EnvTypes.prod ? trainingJobTag : undefined,
      });
      if (train) {
        dispatch(actions.setModalOpen(false));
        dispatch(
          notifierActions.enqueueSnackbar(
            i18n.t("machine.detail.oneClickTraining.modal.success")
          )
        );
      }
    } catch (error) {
      dispatch(actions.setLoading(false));
      dispatch(
        notifierActions.enqueueSnackbar(
          i18n.t("machine.detail.oneClickTraining.modal.fail")
        )
      );
    }
  };
