import { memo, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import removeEvent from "api/handlers/event/removeEvent";
import removeSingleEvent from "api/handlers/event/removeSingleEvent";
import MenuItem from "@mui/material/MenuItem";
import ConfirmPopup from "components/confirmPopup";
import { makeStyles } from "@mui/styles";
import { EventModal } from "components/events";
import { useTranslation } from "react-i18next";
import { dateToDDMMYYYY, dateToHHMM } from "shared/helpers";
import {
  refetchEventsSilent,
  groupAlerts,
  ungroupAlerts,
  snoozePlacements,
} from "store/events/actions";
import { AppState } from "store";
import { KebabMenu } from "components/kebab";
import { prepareLinkForCheckSound, cmp } from "shared/helpers";
import appUrls from "shared/appUrls";
import { DashboardTypes } from "enums/grafana";
import { EventType as ET } from "components/machine/types";
import SnoozeModal from "./snoozeModal";
import usePermissions from "shared/hooks/usePermissions";
import EPermissions from "shared/permissions";

const eventsWithCheckDataOption = [
  ET.machine_on,
  ET.machine_off,
  ET.error,
  ET.anomaly,
  ET.anomaly_warning,
  ET.anomaly_ok,
  ET.maintenance_start,
  ET.maintenance_end,
  ET.nsw_maintenance_start,
  ET.nsw_maintenance_end,
  ET.broken_start,
  ET.broken_end,
  ET.out_of_service_start,
  ET.out_of_service_end,
  ET.nominal_sound_start,
  ET.nominal_sound_end,
  ET.additional_training_data_start,
  ET.additional_training_data_end,
  ET.production_note_start,
  ET.production_note_end,
  ET.data_label_start,
  ET.data_label_end,
];

export const singleEvents = [
  ET.anomaly,
  ET.anomaly_warning,
  ET.anomaly_ok,
  ET.error,
  ET.machine_on,
  ET.machine_off,
  ET.placement_connected,
  ET.placement_disconnected,
  ET.device_recording_plan_changed,
  ET.channel_gain_changed,
  ET.model_training_completed,
  ET.combined_dashboard_updated,
  ET.production_mode_updated,
];

export const editableSingleEvents = [ET.significant_nominal_state_change];

const useStyles = makeStyles((theme: any) => {
  return {
    button: {
      margin: theme.spacing(0, 2.5),
      color: theme.custom.palette.data,
    },
    paper: {
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1, 0, 1, 0),
      minWidth: 100,
    },
    item: {
      fontSize: theme.custom.typography.fontSize[14],
      color: theme.custom.palette.data,
      fontWeight: theme.custom.typography.fontWeight.normal,
    },
  };
});

export const ActionsButton = memo(
  ({
    cell: {
      value: {
        id: eventId,
        type,
        relatedEvent,
        relatedPairEvent,
        createdAt,
        alert_severity,
        note,
        eventPlacements,
        maintenance_type: maintenanceType,
        masterAlert,
        groupedAlerts,
        events,
      },
    },
  }: any) => {
    const {
      machine: { machine },
      placements: { placements, loading, reloading },
    } = useSelector((state: AppState) => state.machineDetail);

    const history = useNavigate();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const { id } = useParams<{ id: string | undefined }>();
    const dispatch: any = useDispatch();
    const user = useSelector((state: AppState) => state.auth.user);

    const canUpdateEvent = usePermissions([
      EPermissions.UPDATE_ANY_EVENT,
      EPermissions.UPDATE_OWN_EVENT,
    ]);

    const canDeleteEvent = usePermissions([
      EPermissions.DELETE_ANY_EVENT,
      EPermissions.DELETE_OWN_EVENT,
    ]);

    const canEditEventPair = usePermissions([
      EPermissions.EDIT_ANY_EVENT_PAIR,
      EPermissions.EDIT_OWN_EVENT_PAIR,
    ]);

    const groupableEvents = events.results.filter(
      (i: any) =>
        !i.masterAlert &&
        !i.groupedAlerts.length &&
        (i.type.codename === "anomaly" ||
          i.type.codename === "anomaly_warning" ||
          i.type.codename === "anomaly_ok") &&
        i.createdAt > createdAt
    );

    const sortedGroupableEvents = groupableEvents.sort(
      cmp((i: any) => i.createdAt)
    );
    const nextGroupableEventId = sortedGroupableEvents.length
      ? sortedGroupableEvents[0].id
      : null;

    const groupableEventIds = groupableEvents.map((i: any) => i.id);

    const [eventModal, setEventModal] = useState<any>(null);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [groupModal, setGroupModal] = useState<any[] | null>(null);
    const [ungroupModal, setUngroupModal] = useState<any[] | null>(null);
    const [snoozeModal, setSnoozeModal] = useState<boolean>(false);

    const isSingle = useMemo(
      () => singleEvents.indexOf(type.codename) !== -1,
      [type.codename]
    );
    const isEditableSingle = useMemo(
      () => editableSingleEvents.indexOf(type.codename) !== -1,
      [type.codename]
    );

    const isEditable = useMemo(
      () =>
        !isSingle ||
        type.codename === ET.anomaly ||
        type.codename === ET.anomaly_warning ||
        type.codename === ET.anomaly_ok,
      [type.codename, isSingle]
    );

    const canDisplayCheckDataOption = useMemo(
      () => eventsWithCheckDataOption.indexOf(type.codename) !== -1,
      [type.codename]
    );

    const [removeEventMutation] = useMutation(removeEvent, {
      onSuccess: () => {
        enqueueSnackbar(t("eventModal.actions.success.delete"));
        dispatch(refetchEventsSilent());
      },
      onError: () => {
        enqueueSnackbar(t("eventModal.errors.delete"));
      },
    });

    const [removeSingleEventMutation] = useMutation(removeSingleEvent, {
      onSuccess: (data: any) => {
        if (data === null) {
          enqueueSnackbar(t("eventModal.errors.deleteWithAttachments"));
        } else {
          enqueueSnackbar(t("eventModal.actions.success.delete"));
          dispatch(refetchEventsSilent());
        }
      },
      onError: () => {
        enqueueSnackbar(t("eventModal.errors.delete"));
      },
    });

    const hideKebabMenu = () => {
      const hideKebab = new CustomEvent("hideKebab");
      const kebab = document.querySelector("#kebab-events-list-" + eventId);
      kebab?.dispatchEvent(hideKebab);
    };

    const openModal = useCallback(
      (event: any) => {
        hideKebabMenu();
        event.stopPropagation();
        if (event.currentTarget.dataset.itemName === "delete") {
          setDeleteModal(true);
        } else {
          setEventModal(event.currentTarget.dataset.itemName);
        }
      },
      [eventId]
    );

    const closeModal = useCallback(() => {
      setEventModal(null);
      setDeleteModal(false);
    }, []);

    const closeMenu = useCallback((event?: any) => {
      event?.stopPropagation();
      setDeleteModal(false);
    }, []);

    const deleteEvent = useCallback(async () => {
      try {
        if (isEditableSingle) {
          await removeSingleEventMutation({ id: eventId });
        } else {
          await removeEventMutation({ id: eventId });
        }
        setDeleteModal(false);
      } catch {
        return;
      }
    }, [eventId, removeEventMutation, removeSingleEventMutation]);

    const handleRedirectSounds = useCallback(() => {
      const dateToTs = (moment(createdAt).valueOf() - 60000) / 1000; // Event date minus twenty minutes
      const decrementedDate = moment.unix(dateToTs).format("YYYY-MM-DD");
      const decrementedTime = moment.unix(dateToTs).format("HH:mm");

      if (
        machine?.id &&
        machine?.project &&
        (eventPlacements.length || placements?.results.length)
      ) {
        const placement = eventPlacements[0] ?? placements?.results[0].id;
        history(
          prepareLinkForCheckSound(
            machine.id,
            placement,
            decrementedDate,
            decrementedTime
          )
        );
      }
    }, [createdAt, history, machine, placements, eventPlacements]);

    const handleRedirectGrafana = useCallback(() => {
      const from = moment(createdAt).valueOf() - 21600000; // Event date minus 6 hours
      const to = moment(createdAt).valueOf() + 21600000; // Event date plus 6 hours

      if (from && to) {
        history(
          appUrls.machines.detail(id) +
            `?tab=${DashboardTypes.combined}&from=${from}&to=${to}`
        );
      }
    }, [createdAt, history, id]);

    const handleSnooze = () => {
      setSnoozeModal(true);
      hideKebabMenu();
    };

    const generateItems = useMemo(() => {
      const typeKey = type?.codename.substring(0, type?.codename.indexOf("_"));

      return (
        <>
          {(type.codename === "anomaly" ||
            type.codename === "anomaly_warning") &&
            canUpdateEvent &&
            !loading &&
            !reloading && (
              <MenuItem
                onClick={handleSnooze}
                id={`snoozeEvent-${eventId}`}
                data-item-name="snooze"
                className={classes.item}
              >
                {t("events.snooze")}
              </MenuItem>
            )}
          {!isSingle && canDeleteEvent && (
            <MenuItem
              onClick={openModal}
              id={`testDeleteEvent-${eventId}`}
              data-item-name="delete"
              className={classes.item}
            >
              {t(`eventModal.actions.${typeKey}.delete`)}
            </MenuItem>
          )}
          {isEditable &&
            ((isSingle && canUpdateEvent) ||
              (!isSingle && canEditEventPair)) && (
              <MenuItem
                data-item-name="edit"
                onClick={openModal}
                id={`testEditEvent-${eventId}`}
                className={classes.item}
              >
                {t(`eventModal.actions.${!isSingle ? typeKey : "single"}.edit`)}
              </MenuItem>
            )}
          {!isSingle &&
            !isEditableSingle &&
            !relatedEvent &&
            canEditEventPair && (
              <MenuItem
                data-item-name="finish"
                onClick={openModal}
                id={`testFinishEvent-${eventId}`}
                className={classes.item}
              >
                {t(
                  `eventModal.actions.${
                    !isSingle ? typeKey : type?.codename
                  }.finish`
                )}
              </MenuItem>
            )}
          {!loading && !reloading && canDisplayCheckDataOption && (
            <MenuItem
              data-item-name="redirect"
              onClick={handleRedirectSounds}
              className={classes.item}
              id={`testRedirectEvent-${eventId}`}
            >
              {t("checkSound")}
            </MenuItem>
          )}
          {(type.codename === "anomaly" ||
            type.codename === "anomaly_warning" ||
            type.codename === "anomaly_ok") &&
            !loading &&
            !reloading &&
            (machine?.dashboards?.anomaly || machine?.dashboards?.combined) && (
              <MenuItem
                data-item-name="redirect"
                onClick={handleRedirectGrafana}
                className={classes.item}
                id={`testRedirectEvent-${eventId}`}
              >
                {t("checkGraph")}
              </MenuItem>
            )}
          {(type.codename === "anomaly" ||
            type.codename === "anomaly_warning" ||
            type.codename === "anomaly_ok") &&
            canUpdateEvent &&
            !loading &&
            !reloading &&
            !masterAlert && (
              <MenuItem
                data-item-name="group-all"
                onClick={() => {
                  setGroupModal([eventId, groupableEventIds, true]);
                  hideKebabMenu();
                }}
                className={classes.item}
                id={`groupEvent-${eventId}`}
              >
                {t("events.groupAll")}
              </MenuItem>
            )}
          {(type.codename === "anomaly" ||
            type.codename === "anomaly_warning" ||
            type.codename === "anomaly_ok") &&
            canUpdateEvent &&
            !loading &&
            !reloading &&
            !!groupableEvents.length &&
            !masterAlert && (
              <MenuItem
                data-item-name="group-page"
                onClick={() => {
                  setGroupModal([eventId, groupableEventIds]);
                  hideKebabMenu();
                }}
                className={classes.item}
                id={`groupEventPage-${eventId}`}
              >
                {t("events.groupPage")}
              </MenuItem>
            )}
          {(type.codename === "anomaly" ||
            type.codename === "anomaly_warning" ||
            type.codename === "anomaly_ok") &&
            canUpdateEvent &&
            !loading &&
            !reloading &&
            !!nextGroupableEventId &&
            !masterAlert && (
              <MenuItem
                data-item-name="group-next"
                onClick={() => {
                  dispatch(groupAlerts(eventId, [nextGroupableEventId]));
                  hideKebabMenu();
                }}
                className={classes.item}
                id={`groupEventNext-${eventId}`}
              >
                {t("events.groupNext")}
              </MenuItem>
            )}
          {(type.codename === "anomaly" ||
            type.codename === "anomaly_warning" ||
            type.codename === "anomaly_ok") &&
            canUpdateEvent &&
            !loading &&
            !reloading &&
            masterAlert && (
              <MenuItem
                data-item-name="ungroup"
                onClick={() => {
                  dispatch(ungroupAlerts(masterAlert, [eventId]));
                  hideKebabMenu();
                }}
                className={classes.item}
                id={`ungroupEvent-${eventId}`}
              >
                {t("events.ungroup")}
              </MenuItem>
            )}
          {(type.codename === "anomaly" ||
            type.codename === "anomaly_warning" ||
            type.codename === "anomaly_ok") &&
            canUpdateEvent &&
            !loading &&
            !reloading &&
            !masterAlert &&
            !!groupedAlerts.length && (
              <MenuItem
                data-item-name="ungroup-all"
                onClick={() => {
                  setUngroupModal([eventId, groupedAlerts]);
                  hideKebabMenu();
                }}
                className={classes.item}
                id={`ungroupEventAll-${eventId}`}
              >
                {t("events.ungroupAll")}
              </MenuItem>
            )}
        </>
      );
    }, [
      classes.item,
      eventId,
      handleRedirectSounds,
      handleRedirectGrafana,
      isSingle,
      isEditable,
      loading,
      maintenanceType,
      openModal,
      relatedEvent,
      reloading,
      t,
      type,
      machine,
    ]);

    const disabled = useMemo(() => {
      if (
        type.codename.indexOf("nsw_maintenance") !== -1 &&
        user?.role !== "nsw_admin"
      ) {
        return true;
      }
      if (
        type.codename === "placement_disabled" ||
        type.codename === "placement_enabled"
      ) {
        return true;
      }
      if (type.codename === "placement_running_threshold_changed") return true;
      if (type.codename === "significant_nominal_state_change") return false;
      if (!canDisplayCheckDataOption) return true;
      return false;
    }, [type.codename, user, canDisplayCheckDataOption]);

    if (!generateItems?.props?.children?.filter((i: any) => !!i)?.length) {
      return null;
    }

    return (
      <>
        <KebabMenu
          disabled={disabled}
          id={"kebab-events-list-" + eventId}
          data={generateItems}
        />
        {deleteModal && (
          <ConfirmPopup
            onConfirm={deleteEvent}
            title={t("eventModal.titles.delete")}
            text={
              !relatedEvent
                ? t("eventModal.titles.deleteText")
                : t("eventModal.titles.deleteTextPair", {
                    eventType: relatedEvent.type.name,
                    timestamp: `${dateToDDMMYYYY(
                      relatedEvent.createdAt
                    )} - ${dateToHHMM(relatedEvent.createdAt)}`,
                  })
            }
            confirmText={t("eventModal.actions.delete")}
            noControl={true}
            onCancel={closeMenu}
          />
        )}
        {!!eventModal && (
          <EventModal
            open={!!eventModal}
            isSingle={isSingle}
            id={eventId}
            relatedId={relatedEvent?.id}
            machineId={Number(id)}
            modalType={eventModal}
            eventType={type}
            onCancel={closeModal}
            alertSeverity={alert_severity}
          />
        )}
        {groupModal && (
          <ConfirmPopup
            onConfirm={() => {
              dispatch(
                groupAlerts(groupModal[0], groupModal[1], groupModal[2])
              );
              setGroupModal(null);
            }}
            title={
              groupModal.length === 3
                ? t("eventModal.titles.groupAll")
                : t("eventModal.titles.groupPage")
            }
            text={
              groupModal.length === 3
                ? t("eventModal.titles.groupAllText")
                : t("eventModal.titles.groupPageText")
            }
            confirmText={
              groupModal.length === 3
                ? t("eventModal.actions.groupAll")
                : t("eventModal.actions.groupPage")
            }
            noControl={true}
            onCancel={() => setGroupModal(null)}
          />
        )}
        {ungroupModal && (
          <ConfirmPopup
            onConfirm={() => {
              dispatch(ungroupAlerts(ungroupModal[0], ungroupModal[1]));
              setUngroupModal(null);
            }}
            title={t("eventModal.titles.ungroupAll")}
            text={t("eventModal.titles.ungroupAllText")}
            confirmText={t("eventModal.actions.ungroupAll")}
            noControl={true}
            onCancel={() => setUngroupModal(null)}
          />
        )}
        <SnoozeModal
          event={eventId}
          placements={eventPlacements}
          open={snoozeModal}
          onSubmit={(value: number) => {
            dispatch(snoozePlacements(eventPlacements, value));
            setSnoozeModal(false);
          }}
          onCancel={() => setSnoozeModal(false)}
        />
      </>
    );
  }
);
