import IMuiTheme from "types/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: IMuiTheme) => ({
  root: {
    padding: theme.spacing(3, 3, 0, 3),
  },
  select: {
    width: "100%",

    "& .MuiInputBase-input": {
      paddingTop: theme.typography.pxToRem(14),
      paddingBottom: theme.typography.pxToRem(14),
    },
    "& .MuiSelect-select": {
      paddingTop: theme.typography.pxToRem(12),
      paddingBottom: theme.typography.pxToRem(12),
    },
  },
  relatedEventSelect: {
    width: "50%",
  },
  relatedEvent: {
    width: "100%",

    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  selectSpacing: {
    padding: "0.75rem 0",
    margin: 0,
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  form: {
    padding: theme.spacing(3, 3, 0, 3),
    height: "100%",
    overflowY: "auto",
  },
  multiSelect: {
    width: "100%",
  },
  input: {
    width: "100%",
  },
  fullHeight: {
    height: "100%",
  },
  inputTimePicker: {
    "& .MuiInputBase-input": {
      paddingTop: theme.typography.pxToRem(17),
      paddingBottom: theme.typography.pxToRem(17),
    },
  },
  order1: {
    order: 1,
  },
  order2: {
    order: 2,
  },
  order3: {
    order: 3,
  },
  order4: {
    order: 4,
  },
  footer: {
    padding: theme.spacing(1),
    boxShadow: "0 0 4px 0 rgba(0,0,0,0.24)",
  },
  radio: {
    marginLeft: theme.spacing(0.5),
  },
  mandatory: {
    paddingLeft: theme.spacing(3),
  },
  modalWrapper: {
    [`@media only screen and (min-width: ${theme.spacing(
      102
    )}) and (min-height: ${theme.spacing(72)})`]: {
      width: "80%",
      height: theme.spacing(75),
    },
    [`@media only screen and (min-width: ${theme.spacing(
      130
    )}) and (min-height: ${theme.spacing(72)})`]: {
      width: "65%",
      height: theme.spacing(80),
    },
    [`@media only screen and (min-width: ${theme.spacing(
      160
    )}) and (min-height: ${theme.spacing(72)})`]: {
      width: "50%",
      height: theme.spacing(80),
    },
    "& .MuiGrid-item": {
      paddingTop: 0,
    },
  },
  description: {
    minHeight: theme.spacing(10),
  },
  detail: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      gap: "1rem",
      flexWrap: "wrap",
    },

    "& > *": {
      width: "100%",
      padding: "0.75rem 0",
      margin: 0,
      [theme.breakpoints.up("md")]: {
        padding: 0,
        minWidth: "23%",
        maxWidth: "40%",
        flex: "1 1 0",
      },
    },
  },

  currencyWrapper: {
    display: "flex",
    gap: "1rem",
  },
  selectCurrency: {
    width: "30%",
  },
}));
