import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { prepareActions } from "store/helpers";
import { EActions, IActionTypes } from "./types";
import getProject from "api/handlers/project/getProject";
import facilitiesActions from "store/projectDetail/facilities/actions";
import usersActions from "store/projectDetail/users/actions";
import { AppState } from "store";
import patchProject from "api/handlers/project/patchProject";
import notifierActions from "store/notifier/actions";
import i18n from "localization";

export const path = "projectDetail/project";

const actionData = [
  [EActions.setLoading, "loading"],
  [EActions.setProject, "project"],
  [EActions.setReset],
];

const actions = prepareActions<IActionTypes, EActions>(actionData, path);

export default actions;

export const reset =
  () => (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(facilitiesActions.setReset());
    dispatch(usersActions.setReset());
    dispatch(actions.setReset());
  };

export const fetchProject =
  (id: number) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      const project = await getProject(id);
      dispatch(actions[EActions.setProject](project));
    } catch (err) {
      return;
    }
  };

export const renameProject =
  (id: number, name: string) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      await patchProject({ id, name });
      dispatch(
        notifierActions.enqueueSnackbar(i18n.t("projects.list.table.success"))
      );

      dispatch(fetchProject(id));
    } catch (error) {
      dispatch(
        notifierActions.enqueueSnackbar(i18n.t("projects.list.table.fail"))
      );
    }
  };

export const updateProject =
  (id: number, params: any) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      await patchProject({
        id,
        service_subject: params.serviceSubject,
        is_temporary_access_token_auth_enabled: params.isTemporaryTokenEnabled,
      });
      dispatch(
        notifierActions.enqueueSnackbar(i18n.t("projects.list.update.success"))
      );

      dispatch(fetchProject(id));
    } catch (error) {
      dispatch(
        notifierActions.enqueueSnackbar(i18n.t("projects.list.update.fail"))
      );
    }
  };
