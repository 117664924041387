import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import i18next from "i18next";

import getAttachments from "api/handlers/event/getAttachments";
import addAttachment from "api/handlers/event/addAttachment";
import removeAttachment from "api/handlers/event/removeAttachment";

import { prepareActions } from "store/helpers";
import { EActions, IAttachmentsAction } from "./types";
import { AppState } from "store";
import notifierActions from "store/notifier/actions";

const { enqueueSnackbar } = notifierActions;

export const path = "attachments";

const pairs = [
  [
    EActions.fetchAttachmentsRequest,
    "event",
    "machine",
    "pageSize",
    "pageIndex",
  ],
  [EActions.fetchAttachmentsFail, "error"],
  [EActions.fetchAttachmentsSuccess, "attachments", "count"],
];

export const actions = prepareActions<IAttachmentsAction, EActions>(
  pairs,
  path
);
export default actions;

export const createAttachment =
  ({ event, name, data }: { event: number; name: string; data: string }) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      dispatch(
        enqueueSnackbar(i18next.t("machine.event.attachment.createInProgress"))
      );
      await addAttachment({ event, name, data });
      dispatch(refetchAttachmentsSilent());
      dispatch(
        enqueueSnackbar(i18next.t("machine.event.attachment.createSuccess"))
      );
    } catch (err: any) {
      if (err?.message === "invalid_attachment_parameter") {
        dispatch(
          enqueueSnackbar(i18next.t("machine.event.attachment.invalidFile"))
        );
      } else {
        dispatch(
          enqueueSnackbar(i18next.t("machine.event.attachment.createError"))
        );
      }
    }
  };

export const deleteAttachment =
  ({ id }: { id: number }) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      await removeAttachment({ id });
      dispatch(refetchAttachmentsSilent());
      dispatch(
        enqueueSnackbar(i18next.t("machine.event.attachment.deleteSuccess"))
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar(i18next.t("machine.event.attachment.deleteError"))
      );
    }
  };

export const fetchAttachments =
  ({
    event,
    machine,
    pageSize,
    pageIndex,
    clear,
  }: {
    event?: number;
    machine?: number;
    pageSize?: number;
    pageIndex?: number;
    clear?: boolean;
  }) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    const { attachments: currentAttachments, showMoreClickedTimesRef } =
      getState().attachments;
    dispatch(
      actions[EActions.fetchAttachmentsRequest](
        event,
        machine,
        pageSize,
        pageIndex,
        clear
      )
    );
    try {
      let { attachments, count } = await getAttachments({
        event,
        machine,
        pageSize,
        pageIndex,
      });
      dispatch(
        actions[EActions.fetchAttachmentsSuccess](
          showMoreClickedTimesRef.current
            ? currentAttachments.concat(attachments)
            : attachments,
          count
        )
      );
    } catch (error) {
      dispatch(actions[EActions.fetchAttachmentsFail]({ error }));
    }
  };

export const refetchAttachmentsSilent =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const { event, machine, pageSize, pageIndex } = getState().attachments;
      const { attachments, count } = await getAttachments({
        event,
        machine,
        pageSize,
        pageIndex,
      });
      dispatch(actions[EActions.fetchAttachmentsSuccess](attachments, count));
    } catch (error) {
      dispatch(actions[EActions.fetchAttachmentsFail](error));
    }
  };
