import { Action } from "redux";
import { EActions, IServiceManagerAction } from "./types";
import { prepareActions } from "store/helpers";
import { AppState } from "store";
import { ThunkDispatch } from "redux-thunk";
import notifierActions from "store/notifier/actions";
import i18n from "localization";
import getServices from "api/handlers/serviceManager/getSerivces";
import getMachines from "api/handlers/serviceManager/getMachines";
import patchMachine from "api/handlers/serviceManager/patchMachine";

export const path = "serviceManager";

const actionsData = [
  [EActions.fetchServicesRequest],
  [EActions.fetchServicesFail, "error"],
  [EActions.fetchServicesSuccess, "services"],

  [EActions.fetchMachinesRequest],
  [EActions.fetchMachinesFail, "error"],
  [EActions.fetchMachinesSuccess, "machines"],
  [EActions.setMachineParams, "machineParams"],
  [EActions.setSupports, "supports"],
  [EActions.setFilter, "filters"],
];

const actions = prepareActions<IServiceManagerAction, EActions>(
  actionsData,
  path
);
export default actions;

export const fetchServices =
  ({ service }: { service?: string }) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchServicesRequest]());
    try {
      const services = await getServices({ service });

      dispatch(actions[EActions.fetchServicesSuccess](services));
    } catch (error) {
      dispatch(actions[EActions.fetchServicesFail](error));
    }
  };

export const fetchMachines =
  ({
    ids,
    service,
    company,
    project,
    facility,
    q,
  }: {
    ids?: number[];
    service?: string[];
    company?: number[] | null;
    project?: number[] | null;
    facility?: number[] | null;
    q?: string;
  }) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchMachinesRequest]());
    try {
      const machines = await getMachines({
        ids,
        service,
        company,
        project,
        facility,
        q,
      });

      const services = await getServices({ service: service?.[0] });

      dispatch(
        actions[EActions.setSupports]({
          disabled: services.results[0].supports_disabled,
          edge: services.results[0].supports_edge,
        })
      );

      dispatch(
        actions[EActions.setMachineParams]({
          ids,
          service,
          company,
          project,
          facility,
          q,
        })
      );

      dispatch(actions[EActions.fetchMachinesSuccess](machines));
    } catch (error) {
      dispatch(actions[EActions.fetchMachinesFail](error));
    }
  };

export const refetchMachines =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const {
        machineParams: { ids, service, company, project, facility, q },
      } = getState().serviceManager;

      const machines = await getMachines({
        ids,
        service,
        company,
        project,
        facility,
        q,
      });
      dispatch(actions[EActions.fetchMachinesSuccess](machines));
    } catch (error) {
      dispatch(actions[EActions.fetchMachinesFail](error));
    }
  };

export const updateMachine =
  ({
    service,
    machine,
    location,
  }: {
    service: string;
    machine: number;
    location: string;
  }) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      await patchMachine({
        service,
        machine,
        location,
      });

      dispatch(refetchMachines());

      dispatch(
        notifierActions.enqueueSnackbar(
          i18n.t("serviceManager.machine.update.success")
        )
      );
    } catch (error) {
      dispatch(
        notifierActions.enqueueSnackbar(
          i18n.t("serviceManager.machine.update.fail")
        )
      );
    }
  };
