import React, { memo, useCallback } from "react";
import { useStyles } from "../modal/components/reportsList/styles";
import { useTranslation } from "react-i18next";
import { ModalWrap } from "./components/reportsList/modal";
import { Box, TextField } from "@mui/material";
import Spinner from "components/spinner";
import { MonthlyReportPicker } from "../modal/components/reportsList/timePicker";
import actions, { addReportsAction } from "store/projectDetail/reports/actions";
import { useDispatch } from "react-redux";
import useData from "dataHooks/projects/reports/dataHook";
import moment from "moment";

interface IReportModal {
  open: boolean;
  projectId: number;
}

const {
  setRecommendations,
  setCreatedFor,
  setModal,
  setError,
  setRecommendationLengthError,
} = actions;

export const AddReportsModal = memo(({ open, projectId }: IReportModal) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch: any = useDispatch();

  const {
    isFetching,

    recommendations,
    createdFor,
    pickerError,

    inputMaxLength,
    recommendationLengthError,
  } = useData(projectId);

  const onSubmit = useCallback(() => {
    const newData = {
      recommendations: recommendations,
      created_for: createdFor,
    };

    dispatch(addReportsAction(newData));
  }, [createdFor, dispatch, recommendations]);

  const onCancel = useCallback(() => {
    dispatch(setModal(null));
    dispatch(setError(null));
    dispatch(setRecommendations(""));
    dispatch(setCreatedFor(""));
    dispatch(setRecommendationLengthError(null));
  }, [dispatch]);

  const handleChange = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch(setRecommendations(target.value));
      if (target.value.length > inputMaxLength) {
        dispatch(
          setRecommendationLengthError(t("reports.recommendations.exceeded"))
        );
      } else {
        dispatch(setRecommendationLengthError(null));
      }
    },
    [dispatch, inputMaxLength]
  );

  const error = pickerError || recommendationLengthError;

  const isDisabled = error ? true : !!!createdFor;

  return (
    <ModalWrap
      title={t("projects.detail.reportsTitle.add")}
      onSubmit={onSubmit}
      open={open}
      onCancel={onCancel}
      cta={t("confirm")}
      submitDisabled={isDisabled}
    >
      {isFetching ? (
        <Spinner />
      ) : (
        <Box
          mr={3}
          ml={3}
          sx={{
            "& .MuiInputBase-root": {
              paddingRight: "15px",
            },
          }}
        >
          <MonthlyReportPicker
            type={t("reports.list.table.monthlyPickerLabel")}
            date={createdFor}
            pickerError={pickerError}
            maxDate={moment()
              .subtract(1, "months")
              .endOf("month")
              .format("YYYY-MM-DD")}
          />
          <Box mt={2} className={classes.inputWrapper}>
            <TextField
              label={t("reports.list.table.recommendationLabel")}
              value={recommendations}
              onChange={handleChange}
              variant="outlined"
              type="text"
              size="small"
              error={!!recommendationLengthError}
              helperText={
                !!recommendationLengthError ? recommendationLengthError : null
              }
              multiline
              rows={5}
              inputProps={{
                className: classes.input,
                min: 0,
              }}
            />
          </Box>
        </Box>
      )}
    </ModalWrap>
  );
});
