import { useState, useEffect } from "react";
import i18n from "localization";

import getAlertSeverities from "api/handlers/getAlertSeverities";
import { store } from "store";
import notifierActions from "store/notifier/actions";
import { Severities } from "enums/severities";

type Severity = {
  id: number;
  codename: string;
  name: string;
  value: string;
};

const useAlertSeverities = () => {
  const [loading, setLoading] = useState(true);
  const [alertSeverities, setAlertSeverities] = useState<Severity[]>([]);

  useEffect(() => {
    getAlertSeverities()
      .then((response: any) => {
        const result = response.filter((item: Severity) =>
          Severities.hasOwnProperty(item.codename)
        );
        setAlertSeverities(result);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        store.dispatch(
          notifierActions.enqueueSnackbar(
            i18n.t("machine.detail.eventsList.resolveSeverity.error")
          )
        );
      });
  }, []);

  return [alertSeverities, loading] as [Severity[], boolean];
};
export default useAlertSeverities;
