import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { SortingRule } from "react-table";
import { resolveSorting } from "shared/helpers";
import axios, { Canceler } from "axios";
import { separateEventTypes } from "shared/eventTypes";
import convertEventFromAPI from "./convertEventFromAPI";
import fillEventUsers from "./fillEventUsers";

interface IEventsParams {
  page?: number;
  pageSize: number;
  orderBy?: SortingRule<number>[];
  machine?: number;
  ids?: number[];
  not_id?: number[];
  eventTypes?: (string | null)[];
  status?: (string | null)[];
  isCritical?: (string | null)[];
  alertSeverity?: (string | null)[];
  communicationStatus?: (string | null)[];
  timeIntervals?: { [key: string]: string | null | undefined };
  notEventTypes?: (string | null)[];
  nextUrl?: string;
  preventCancel?: boolean;
  hasMasterAlert?: boolean;
  groupedAlerts?: number;
  q?: string;
}

let cancel: Canceler | undefined;

export default async function getEvents({
  page,
  pageSize,
  orderBy,
  machine,
  ids,
  not_id,
  eventTypes,
  status,
  isCritical,
  alertSeverity,
  communicationStatus,
  timeIntervals,
  notEventTypes,
  nextUrl,
  preventCancel,
  hasMasterAlert,
  groupedAlerts,
  q,
}: IEventsParams) {
  const params = {
    ps: pageSize,
    page,
    machine,
    order_by: resolveSorting("", orderBy),
    id: ids?.length ? ids : undefined,
    not_id: not_id?.length ? not_id : undefined,
    type: separateEventTypes(eventTypes),
    not_type: separateEventTypes(notEventTypes),
    from: timeIntervals?.from,
    to: timeIntervals?.to,
    status,
    is_critical: isCritical,
    alert_severity: alertSeverity,
    communication_status: communicationStatus,
    has_master_alert: hasMasterAlert,
    grouped_alerts: groupedAlerts,
    q,
  };

  const url = endpoints.events.default;

  if (cancel) {
    cancel();
  }

  try {
    const result = await axiosNeuron.get(nextUrl ?? url, {
      params: nextUrl ? {} : params,
      // https://axios-http.com/docs/cancellation#:~:text=The%20axios%20cancel%20token%20API,the%20withdrawn%20cancelable%20promises%20proposal.&text=You%20can%20create%20a%20cancel,const%20CancelToken%20%3D%20axios.
      // cancelToken is not always desired feature - can be turned off by using preventCancel
      cancelToken: preventCancel
        ? undefined
        : new axios.CancelToken(function executor(c) {
            cancel = c;
          }),
    });
    if (!result) {
      return null;
    }
    const {
      data: { count, next, previous, results, unresolved },
    } = result;
    const usersIds: number[] = [];
    const eventsData = {
      count,
      next,
      previous,
      unresolved,
      results: results.map((result: any) =>
        convertEventFromAPI(result, usersIds)
      ),
    };
    await fillEventUsers(eventsData.results, usersIds);
    return eventsData;
  } catch (error) {
    if (error) {
      throw error;
    }
    return {} as any;
  }
}
