import React, { useState, useRef, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { SelectChangeEvent } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import _debounce from "lodash/debounce";
import { debounceTime } from "settings";

import Spinner from "components/spinner";
import Button from "components/button";
import FormHelperText from "@mui/material/FormHelperText";

const useStyles = makeStyles((theme: any) => {
  return {
    root: { width: "100%" },
    select: {
      height: theme.spacing(6),
    },
    label: {
      marginTop: theme.spacing(0.5),
    },
    searchIcon: {
      color: theme.custom.palette.placeholder,
    },
    textField: {
      height: theme.spacing(4),
    },
    searchInput: {
      fontSize: theme.custom.typography.fontSize[14],
      fontWeight: theme.custom.typography.fontWeight.normal,
      color: theme.custom.palette.data,
      letterSpacing: theme.custom.typography.letterSpacing.small,
      "&::placeholder": {
        fontSize: theme.custom.typography.fontSize[14],
        fontWeight: theme.custom.typography.fontWeight.normal,
        color: theme.custom.palette.placeholder,
        letterSpacing: theme.custom.typography.letterSpacing.small,
      },
    },
    searchBox: {
      boxShadow: `0 ${theme.spacing(0.5)} ${theme.spacing(
        0.5
      )} -${theme.spacing(0.5)} ${theme.custom.palette.shadow}`,
    },
    loadMoreItem: {
      display: "flex",
      justifyContent: "center",
    },
  };
});

interface Option {
  value: string | number;
  text: string;
}

interface Props {
  id: string;
  label: string;
  searchPlaceholder?: string;
  options: { value: string | number; text: string }[];
  disabled?: boolean;
  value: string | number;
  onChange?: (
    event: SelectChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => void;
  loading?: boolean;
  loadMore?: () => void;
  loadingMore?: boolean;
  onSearch?: (val: string) => void;
  required?: boolean;
  helperText?: string;
  error?: boolean;
  defaultValue?: any;
}

const SearchMenu = ({
  id,
  label,
  value,
  options,
  onChange,
  disabled,
  searchPlaceholder,
  loading,
  loadMore,
  loadingMore,
  onSearch,
  required,
  helperText,
  error,
  defaultValue,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const inputLabel = useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);
  const loadMoreFnRef = useRef<(val: string) => void | null>();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleChange = useCallback(
    (
      event: SelectChangeEvent<{ name?: string | undefined; value: unknown }>,
      child: React.ReactNode
    ) => {
      if (event.target.value) {
        if (onChange) onChange(event, child);
        setIsOpen(false);
      }
    },
    [onChange]
  );

  const [searchPhrase, setSearchPhrase] = useState("");

  const filterOptions = (options: Option[], searchPhrase: string) => {
    const outputOptions: Option[] = [];
    options.forEach((option: any) => {
      if (option?.text.toLowerCase().includes(searchPhrase.toLowerCase())) {
        outputOptions.push(option);
      }
    });
    return outputOptions;
  };

  useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth);
  }, []);

  useEffect(() => {
    if (loadMore) {
      loadMoreFnRef.current = loadMore;
    }
  }, [loadMore]);

  const handleSearch = () => {
    if (onSearch && loadMoreFnRef.current) onSearch(searchPhrase);
  };

  const debouncedSetSearch = React.useMemo(
    () =>
      _debounce((input: string) => {
        onSearch && onSearch(input);
      }, debounceTime),
    []
  );

  useEffect(() => {
    debouncedSetSearch(searchPhrase);
  }, [searchPhrase]);

  return (
    <FormControl
      variant="outlined"
      className={classes.root}
      size="small"
      required={required}
      error={error}
    >
      <InputLabel
        id="searchMenuLabelId"
        ref={inputLabel}
        htmlFor={id}
        className={classes.label}
      >
        {label}
      </InputLabel>
      <Select
        id={id}
        labelId="searchMenuLabelId"
        label={label}
        // defaultValue={defaultValue}
        className={classes.select}
        value={
          options.filter((option: Option) => option.value === value)[0] ??
          defaultValue
        }
        open={isOpen}
        onOpen={() => {
          setIsOpen(true);
        }}
        onChange={handleChange}
        disabled={disabled}
        MenuProps={{
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          onClose: () => {
            setIsOpen(false);
          },
        }}
      >
        <Box p={2} pt={1} mb={2} className={classes.searchBox}>
          <TextField
            placeholder={searchPlaceholder}
            id={id + "SearchInput"}
            className={classes.textField}
            fullWidth
            variant="outlined"
            onClick={(e: any) => e.stopPropagation()}
            value={searchPhrase}
            onChange={(e: any) => {
              setSearchPhrase(e.target.value);
            }}
            onKeyDown={(e: any) => {
              e.stopPropagation();
            }}
            InputProps={{
              classes: {
                input: classes.searchInput,
              },
              startAdornment: (
                <InputAdornment position="start" onClick={handleSearch}>
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {loading ? (
          <Spinner />
        ) : (
          filterOptions(
            options,
            onSearch && loadMoreFnRef.current ? "" : searchPhrase
          ).map((option: any) => (
            <MenuItem key={option?.value} value={option?.value} dense>
              {option?.text}
            </MenuItem>
          ))
        )}
        {loadMore &&
          (loadingMore ? (
            <Spinner />
          ) : (
            <MenuItem key={`loadMore-${id}`} className={classes.loadMoreItem}>
              <Button onClick={loadMore}>{t("loadMore")}</Button>
            </MenuItem>
          ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SearchMenu;
