import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Spinner from "components/spinner";
import useData from "dataHooks/eventSounds/similaritySearch";
import { ISimilaritySearch } from "types/eventSounds";
import { AppState } from "store";
import Table from "components/table";
import Row from "components/table/row";
import Header from "components/table/header";
import { createColumns } from "./columns";
import getDecodedFile from "api/handlers/eventSounds/getDecodedFile";
import { getPeaks } from "../components/card";
import { Player } from "../components/Player";
import Footer from "../components/footer";
import SaveModal from "../savedDataset/saveWindow";
import actions from "store/eventSounds/actions";

const SimilaritySearchTab: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { searchParams, selectedChunks } = useSelector(
    (state: AppState) => state.eventSounds
  );

  const {
    isFetching,
    similaritySounds,
    initialState,
    showMoreClickedTimesRef,
    originalSound,
    onPageChange,
  } = useData({
    searchParams,
  });
  const menuOpened = useSelector(
    (state: AppState) => state.layout.menu,
    shallowEqual
  );

  const upMd = useMediaQuery(theme.breakpoints.up("md"), { noSsr: true });
  const dispatch: any = useDispatch();

  const [loadedEvents, setLoadedEvents] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const defaultChunks = upMd ? 50 : 10;
  const [chunksPerPage, setChunksPerPage] = useState(defaultChunks);

  useEffect(() => {
    const fetchData = async () => {
      const initialData = similaritySounds?.results?.slice(0, defaultChunks);
      setIsLoading(true);

      if (initialData) {
        const promises = initialData.map(async (chunk: ISimilaritySearch) => {
          const buffer = await getDecodedFile({
            url: chunk.url,
          });
          const res = {
            ...chunk,
            buffer,
          };
          return res;
        });

        const updatedData = promises.length ? await Promise.all(promises) : [];
        setLoadedEvents(updatedData);
      }
    };

    fetchData();
  }, [similaritySounds, defaultChunks]);

  const loadMoreCards = () => {
    const startIndex = currentPage * chunksPerPage;
    const endIndex =
      chunksPerPage === 50 ? defaultChunks + 10 : startIndex + chunksPerPage;
    const newCards = similaritySounds?.results?.slice(startIndex, endIndex);
    const fetchData = async () => {
      if (newCards) {
        const promises = newCards.map(async (chunk: ISimilaritySearch) => {
          try {
            const buffer = await getDecodedFile({
              url: chunk.url,
            });
            const res = {
              ...chunk,
              buffer,
            };
            return res;
          } catch (error) {
            throw new Error(`${error}`);
          }
        });
        const updatedData = await Promise.all(promises);

        setLoadedEvents((prevLoadedChunks: any) => {
          const uniqueIds = new Set(
            prevLoadedChunks.map((item: any) => item?.id)
          );

          const filteredUpdatedData = updatedData?.filter(
            (item: any) => !uniqueIds.has(item?.id)
          );
          return [...prevLoadedChunks, ...filteredUpdatedData];
        });
      }
    };

    fetchData();

    setCurrentPage(currentPage + 1);
    setChunksPerPage(10);
  };

  const handlePageChange = (pageIndex: number) => {
    setChunksPerPage(50);
    setCurrentPage(1);
    onPageChange(pageIndex);
  };

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 200 &&
      loadedEvents.length < similaritySounds?.results.length
    ) {
      loadMoreCards();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loadedEvents, handleScroll]);
  const columns = useMemo(() => createColumns(t), [t]);

  const resultsData =
    loadedEvents && loadedEvents?.length ? [{ chunks: loadedEvents }] : [];

  const [isPlayingSound, setIsPlayingSound] = useState(false);

  const resetState = useCallback(() => {
    dispatch(actions.setSelectedChunks([]));
  }, [dispatch]);

  const onSave = useCallback(() => {
    dispatch(actions.setOpenModal(true));
  }, [dispatch]);

  return (
    <>
      {originalSound && (
        <Box
          style={{
            borderRadius: "0.5rem",
            border: `0.0625rem solid #E0E0E0`,
            padding: "1rem",
            maxWidth: 435,
          }}
          mt={0.5}
        >
          <div
            style={{
              width: "400.5px",
              border: `1px solid grey`,
              height: 134,
            }}
          >
            <Player
              buffer={originalSound}
              getPeaks={getPeaks}
              canvasWidth={400}
              canvasWaveHeight={20}
              canvasSpectroHeight={60}
              isPlaying={isPlayingSound}
              setIsPlaying={setIsPlayingSound}
            />
          </div>
        </Box>
      )}
      {isFetching ? (
        <Spinner />
      ) : (
        <>
          <Table<any>
            showMoreClickedTimesRef={showMoreClickedTimesRef}
            isLoading={isFetching}
            data={resultsData}
            rowsCount={
              similaritySounds
                ? similaritySounds.results.length + initialState.pageSize
                : 0
            }
            columns={columns}
            initialState={initialState}
            onPageChange={handlePageChange}
            RowComponent={Row}
            HeaderComponent={Header}
            RowComponentProps={{
              rowWidth: "100%",
            }}
            hideNoData={!(!isFetching && !!loadedEvents?.length)}
            lazyLoad={
              isLoading &&
              !isFetching &&
              similaritySounds?.results.length > loadedEvents?.length
            }
            infinitePaging={true}
          />
        </>
      )}
      <Footer
        opened={menuOpened}
        onSave={onSave}
        resetState={resetState}
        data={selectedChunks}
        cancel="cancel"
        cta="confirmationBar.saveDataset"
      />
      <SaveModal />
    </>
  );
};
export default SimilaritySearchTab;
