import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import i18next from "i18next";

import { EActions, ITemporaryTokenAction } from "./types";
import { prepareActions } from "store/helpers";
import { AppState } from "store";
import removeTemporaryToken from "api/handlers/temporaryToken/removeTemporaryToken";
import notifierActions from "store/notifier/actions";
import patchTemporaryToken from "api/handlers/temporaryToken/patchTemporaryToken";
import addTemporaryToken from "api/handlers/temporaryToken/addTemporaryToken";
import getTemporaryTokens from "api/handlers/temporaryToken/getTemporaryTokens";
import getProjects from "api/handlers/project/getProjects";
import getUsers from "api/handlers/user/getUsers";

const { enqueueSnackbar } = notifierActions;
export const path = "projectDetail/accessToken";

const actionsData = [
  [EActions.setAccessTokenFilter, "filters"],
  [EActions.setSearch, "q"],
  [EActions.setPage, "pageIndex"],
  [EActions.fetchAccessTokenRequest, "silent"],
  [EActions.fetchAccessTokenFail, "error"],
  [EActions.fetchAccessTokenSuccess, "temporaryTokenData"],
  [EActions.fetchTemporaryTokenParams, "temporaryTokenFetchParams"],
  [EActions.setReset],
  [EActions.setMore, "more"],
  [EActions.setModal, "modalOpened"],
  [EActions.setReadOnly, "readOnly"],
  [EActions.setExpiresAt, "expiresAt"],
  [EActions.setMachines, "machines"],
];

const actions = prepareActions<ITemporaryTokenAction, EActions>(
  actionsData,
  path
);
export default actions;

export const fetchTemporaryToken =
  (params: any) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchAccessTokenRequest](params.silent));
    try {
      const tokens = await getTemporaryTokens(params);
      const ids = tokens.results.map((item: any) => item.project);
      const userIds = tokens.results.map((item: any) => item.created_by_user);
      const projects = await getProjects({ ids });
      const users = await getUsers({ ids: userIds });

      const tokenValue = {
        ...tokens,
        results: tokens.results.map((token: any) => {
          const projectData = projects?.results.find(
            (project) => project.id === token.project
          );
          const userData = projects?.results.find(
            (project) => project?.user_ro?.id === token.user
          );
          const creators = users?.results.find(
            (user) => user.id === token.created_by_user
          );
          const createdBy =
            creators?.firstName || creators?.lastName
              ? `${creators?.firstName} ${creators?.lastName}`
              : creators?.email;
          return {
            ...token,
            createdBy,
            userRoId: userData?.user_ro?.id,
            projectName: projectData?.projectName,
            users: { value: creators?.id, text: createdBy },
            projects: {
              value: projectData?.id,
              text: projectData?.projectName,
            },
          };
        }),
      };

      dispatch(actions[EActions.fetchTemporaryTokenParams](params));
      dispatch(actions[EActions.fetchAccessTokenSuccess](tokenValue));
    } catch (error) {
      dispatch(actions[EActions.fetchAccessTokenFail](error));
    }
  };

export const refetchTemporaryTokensSilent =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const { temporaryTokenFetchParams } =
        getState().projectDetail.temporaryAccessToken;
      const tokens = await getTemporaryTokens(temporaryTokenFetchParams);
      dispatch(actions[EActions.fetchAccessTokenSuccess](tokens));
    } catch (error) {
      dispatch(actions[EActions.fetchAccessTokenFail](error));
    }
  };

export const deleteTemporaryToken =
  ({ id }: { id: number }) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      await removeTemporaryToken({ id });
      dispatch(refetchTemporaryTokensSilent());
      dispatch(
        enqueueSnackbar(
          i18next.t("projects.detail.temporaryToken.delete.success")
        )
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar(
          i18next.t("projects.detail.temporaryToken.delete.error")
        )
      );
    }
  };
export const udpateTemporaryToken =
  (id: number, data: any) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      await patchTemporaryToken({ id, ...data });
      dispatch(refetchTemporaryTokensSilent());
      dispatch(actions[EActions.setExpiresAt](null));
      dispatch(actions[EActions.setMachines]([]));
      dispatch(
        enqueueSnackbar(
          i18next.t("projects.detail.temporaryToken.update.success")
        )
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar(
          i18next.t("projects.detail.temporaryToken.update.error")
        )
      );
    }
  };
export const addTemporaryTokenAction =
  (data: any) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    const { project } = getState().projectDetail.project;

    const newData = {
      ...data,
      user: data.readOnly ? project.user_ro.id : project.user_rw.id,
    };

    try {
      await addTemporaryToken({ ...newData });
      dispatch(refetchTemporaryTokensSilent());
      dispatch(actions[EActions.setModal](false));
      dispatch(actions[EActions.setExpiresAt](null));
      dispatch(actions[EActions.setReadOnly](false));
      dispatch(actions[EActions.setMachines]([]));
      dispatch(
        enqueueSnackbar(i18next.t("projects.detail.temporaryToken.add.success"))
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar(i18next.t("projects.detail.temporaryToken.add.error"))
      );
    }
  };
