import React, { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Picker from "localization/pickerLocale";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useStyles } from "./styles";
import { IReportsList } from "types/report";
import actions from "store/projectDetail/reports/actions";
import { useDispatch } from "react-redux";
import useDataFilteredReports from "dataHooks/projects/reports/filteredHook";
import Spinner from "components/spinner";
import moment from "moment";
import dayjs from "dayjs";

type TTimeInterval = {
  date?: string | undefined;
  type: string;
  maxDate?: string | any;
  minDate?: string;
  pickerError: string | null;
};

const { setError, setCreatedFor } = actions;

export const MonthlyReportPicker = memo(
  ({ type, date, maxDate, minDate, pickerError }: TTimeInterval) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch: any = useDispatch();

    const [dateVal, setDateVal] = useState(date);

    const { filteredReports, filterLoading } = useDataFilteredReports({
      createdFor: date!,
    });

    const validateRange = useCallback(
      (date: string) => {
        const formatted = moment(date).format("YYYY-MM");

        const isInValidDate = filteredReports?.results?.some(
          (item: IReportsList) => item.created_for.includes(formatted)
        );

        if (isInValidDate) {
          dispatch(setError(t("reports.pickersError.overlaps")));
        } else {
          dispatch(setError(null));
        }
      },
      [dispatch, filteredReports, t]
    );

    const changeDate = useCallback(
      (date: any) => {
        let value = date === null ? undefined : date;
        setDateVal(value);
        const parsedDate = moment(value).format("YYYY-MM-DD");
        dispatch(setCreatedFor(parsedDate));

        if (value === undefined) {
          dispatch(setError(null));
        }
      },
      [dispatch]
    );

    useEffect(() => {
      if (dateVal) {
        validateRange(dateVal!);
      }
    }, [dateVal, type, validateRange, filteredReports]);

    return filterLoading ? (
      <Spinner />
    ) : (
      <Picker
        Component={DatePicker}
        ampm="false"
        className={classes.fullWidth}
        maxDate={maxDate ? dayjs(maxDate) : undefined}
        minDate={minDate ? dayjs(minDate) : undefined}
        label={t(type)}
        value={date ? dayjs(date) : null}
        onChange={changeDate}
        clearable
        format="YYYY/MM"
        slots={{
          openPickerIcon: AccessTimeIcon,
        }}
        slotProps={{
          textField: {
            variant: "outlined",
            error: !!pickerError,
            helperText: !!pickerError ? pickerError : null,
          },
        }}
      />
    );
  }
);
