import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import { resolveSorting } from "shared/helpers";

type IParams = {
  pageSize?: number;
  page?: number;
  orderBy?: any;
  startDate?: Date | null;
  endDate?: Date | null;
  q?: string | null;
};

export default async function getDatasetGroups(paramsInput: IParams) {
  const url = endpoints.eventSounds.datasetGroup;
  const params = {
    ps: paramsInput.pageSize,
    page: paramsInput.page ? paramsInput.page : 9999,
    order_by: resolveSorting(paramsInput?.orderBy?.id, paramsInput?.orderBy),
    from: paramsInput?.startDate
      ? paramsInput!.startDate.toISOString()
      : undefined,
    to: paramsInput?.endDate ? paramsInput!.endDate.toISOString() : undefined,
    q: paramsInput?.q ? paramsInput!.q : undefined,
  };

  try {
    const response = await axiosNeuron.get(url, { params });
    const { data } = response;
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
